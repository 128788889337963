<template>
  <Transition name="feedSlide">
    <div class="feed-overlay" v-if="newOraclesStore.showFeedDetails">
      <div
        class="feed-info-wrapper"
        :style="`transform:translateY(${translateY}px)`"
      >
        <div class="feed-info" ref="refFeedInfo">
          <DeployerFeedTopBar @close-swiping="swiping" />
          <div class="inner">
            <div class="mobile-hide">
              <div class="subtitle">{{ texts.feed_overview }}</div>
              <client-only>
                <DeployerFeedGraph
                  :transactions="transactions"
                  :show-graph="showGraph"
                  v-model="showLastDays"
                />
              </client-only>
            </div>
            <div class="subtitle">{{ texts.data_sources }}</div>
            <DeployerFeedDataSources
              :sources="aggregatedFeed"
              :labels="labels"
              v-if="aggregatedFeed && width > 1023"
            />
            <DeployerFeedDataSourcesMobile
              :sources="aggregatedFeed"
              :labels="labels"
              v-if="aggregatedFeed && width <= 1023"
            />
            <div class="subtitle">{{ texts.latest_updates }}</div>
            <DeployerFeedUpdates />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup>
const newOraclesStore = useNewOraclesStore()
const textsStore = useTextsStore()
const texts = computed(() => {
  return textsStore.list.price_feeds
})

const { width } = useWindowSize()

const refFeedInfo = ref(null)
const feedApiData = ref(null)
const loading = ref(true)
const showLastDays = ref(30)
const showGraph = ref(false)
const translateY = ref(0)

const labels = computed(() => {
  return texts.value.sources_labels
})

const swiping = (lenghtY) => {
  if (lenghtY > 0) {
    translateY.value = 0
  }
  translateY.value = -lenghtY
}

onClickOutside(refFeedInfo, () => {
  newOraclesStore.showFeedDetails = false
})

const now = Math.floor(Date.now() / 1000)

const loadFeedData = async () => {
  feedApiData.value = await $fetch(
    `https://api.diadata.org/oraclebuilder-prod/dashboard?address=${
      newOraclesStore.selectedFeed.address
    }&chainID=${newOraclesStore.selectedFeed.chainId}&starttime=${
      now - 30 * 24 * 3600
    }&endtime=${now}&symbol=${newOraclesStore.selectedFeed.symbol}/USD`
  )

  setTimeout(() => {
    showGraph.value = true
  }, 500)

  loading.value = false

  if (
    !newOraclesStore.oracles[
      newOraclesStore.selectedFeed.address +
        '-' +
        newOraclesStore.selectedFeed.chainId +
        '-' +
        newOraclesStore.selectedFeed.symbol
    ]
  ) {
    newOraclesStore.oracles[
      newOraclesStore.selectedFeed.address +
        '-' +
        newOraclesStore.selectedFeed.chainId +
        '-' +
        newOraclesStore.selectedFeed.symbol
    ] = {
      assets: feedApiData.value?.Assets
    }
  }
}

const transactions = computed(() => {
  let ret = [
    {
      name: 'Price',
      type: 'area',
      data: []
    }
  ]
  const now = Math.floor(Date.now() / 1000)
  const showAfter = now - showLastDays.value * 24 * 3600

  feedApiData.value?.Transactions.forEach((item) => {
    if (Math.floor(new Date(item.UpdateTime) / 1000) > showAfter) {
      ret[0].data.push([
        item.UpdateTime,
        (item.AssetPrice / 10 ** 8).toFixed(2)
      ])
    }
  })

  return ret
})

const aggregatedFeed = computed(() => {
  return newOraclesStore.feedAggregations[
    newOraclesStore.selectedFeed.address +
      '-' +
      newOraclesStore.selectedFeed.chainId +
      '-' +
      newOraclesStore.selectedFeed.symbol
  ]?.GetFeedAggregation
})

watch(
  () => newOraclesStore.showFeedDetails,
  (value) => {
    const body = document.querySelector('body')
    if (value) {
      body?.classList.add('overflow-hidden', 'price-feed-opened')
      loadFeedData()
    } else {
      body?.classList.remove('overflow-hidden', 'price-feed-opened')
      newOraclesStore.selectedFeed = null
      feedApiData.value = null
      showGraph.value = false
    }
  }
)
</script>

<style lang="scss" scoped>
.feed-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;

  @media (max-width: 1023px) {
    top: 50px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
  }
}

.feed-info-wrapper {
  height: 100dvh;
}

.feed-info {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 1000px;
  border-radius: 10px 0px 0px 10px;
  border-left: 1px solid var(--field-stroke, rgba(0, 0, 0, 0.2));
  background: var(--Page-bg, #fafafa);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  overflow-y: auto;

  @media (max-width: 1023px) {
    width: 100%;
    border-radius: 0;

    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 3px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.2);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.2);
  }
}

.subtitle {
  margin-top: 40px;
  margin-bottom: 10px;
}

.inner {
  padding-inline: 30px;
  padding-bottom: 50px;
  @media (max-width: 1023px) {
    padding-inline: 15px;
  }
}

.feedSlide-enter-active,
.feedSlide-leave-active {
  transition: opacity 0.25s;

  .feed-info {
    transition: transform 0.25s;
  }
}

.feedSlide-enter-from,
.feedSlide-leave-to {
  opacity: 0;

  .feed-info {
    @media (min-width: 1024px) {
      transform: translateX(100%);
    }
    @media (max-width: 1023px) {
      transform: translateY(100%);
    }
  }
}

.mobile-hide {
  @media (max-width: 1023px) {
    display: none;
  }
}
</style>

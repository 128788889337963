import { defineStore } from 'pinia'

export const useMainStore = defineStore('mainStore', {
  state: () => ({
    testVal: 'test',
    logo: {
      src: 'https://cms3.diadata.org/wp-content/uploads/2022/07/Polygon.svg',
      alt: 'Polygon Logo',
      width: 125,
      height: 28
    }
  })
})

<template>
  <div class="inner-box">
    <div class="inner-box__title">
      Top up executed
      <span class="inner-box__subtitle">via connected wallet</span>
    </div>
    <p class="inner-box__text">
      Transaction successfully completed. It might take few minutes until the
      refill takes effect in the dashboard.
    </p>

    <div class="info-row">
      <div class="info-row__label">Transaction hash</div>
      <div class="info-row__value">
        <a :href="externalLink" target="_blank" class="hash-link icon-label"
          >{{ shortenAddress(newOraclesStore.topUpTXHash) }}
          <span v-html="ExternalSvg"
        /></a>
      </div>
    </div>
    <button type="button" class="btn cta w100" @click="modalStore.closeModal">
      Return to manage dashboard
    </button>
  </div>
</template>

<script setup>
import ExternalSvg from '~/assets/icons/external.svg?raw'
const newOraclesStore = useNewOraclesStore()

const modalStore = useModalStore()

const externalLink = computed(() => {
  return `https://etherscan.io/tx/${newOraclesStore.topUpTXHash}`
})
</script>

<style lang="scss" scoped>
.info-row {
  margin-bottom: 20px !important;
}

.hash-link {
  color: var(--CTA, #3d6ef6);

  :deep(svg) {
    position: relative;
    top: -1px;
  }
}
</style>

<template>
  <button type="button" @click.prevent.stop="copyValue" class="copy-btn">
    <span v-html="CopySvg"></span>

    <transition name="showCopied">
      <div class="copied" v-if="copied">Copied</div>
    </transition>
  </button>
</template>

<script setup>
import CopySvg from '~/assets/icons/copy.svg?raw'

const props = defineProps({
  copyValue: String
})

const copied = ref(false)

const copyValue = () => {
  copyToClipboard(props.copyValue)
  copied.value = true
  setTimeout(() => {
    copied.value = false
  }, 1000)
}
</script>

<style lang="scss" scoped>
.copy-btn {
  position: relative;
}

.copied {
  position: absolute;
  bottom: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.3rem;
  background: var(--Banner-5, rgba(0, 0, 0, 0.05));
  font-weight: 500;
  line-height: 100%;
  padding: 4px 5px 2px;
  border-radius: 4px;
  opacity: 0.7;
}

.showCopied-enter-active,
.showCopied-leave-active {
  transition:
    opacity 0.3s,
    transform 0.3s;
}

.showCopied-enter-from,
.showCopied-leave-to {
  opacity: 0;
  transform: translate(-50%, 10px);
}
</style>

import { defineStore } from 'pinia'

export const useDeployerStore = defineStore('deployerStore', {
  state: () => ({
    client: 'clientX',
    plans: [],
    chainIdsAvailable: [],
    summaryMobileOpened: false,
    selectedAssetsCount: 0
  }),
  getters: {
    selectedPlan() {
      return this.plans.find((p) => p.selected)
    }
  },
  actions: {
    selectPlan(planNameOrId) {
      this.plans.forEach((p) => {
        p.selected = p.name === planNameOrId || Number(p.id) === planNameOrId
      })
    },
    setPlans(plans) {
      this.plans = plans
    }
  }
})

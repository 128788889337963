export default function (heartbeat, full = false) {
  const formatTime = (value, unit) =>
    full ? `${value} ${unit}` : `${value}${unit.charAt(0)}`

  if (heartbeat < 120) {
    return formatTime(heartbeat, 'seconds')
  } else if (heartbeat < 3600) {
    return formatTime(Math.floor(heartbeat / 60), 'minutes')
  } else {
    const hours = Math.floor(heartbeat / 3600)
    return formatTime(hours, hours === 1 ? 'hour' : 'hours')
  }
}

<template>
  <div class="data-sources">
    <div class="dsr header">
      <div class="dsr__col">{{ props.labels[0].label }}</div>
      <div class="dsr__col text-right">{{ props.labels[1]?.label }}</div>
      <div class="dsr__col text-right">{{ props.labels[2]?.label }}</div>
      <div class="dsr__col text-right">{{ props.labels[3]?.label }}</div>
      <div class="dsr__col text-right">{{ props.labels[4]?.label }}</div>
      <div class="dsr__col text-right pr20">{{ props.labels[5]?.label }}</div>
    </div>
    <DeployerFeedDataSourceRow
      v-for="item in groupedPairs"
      :key="`dsr-${item.Exchange}`"
      :group="item"
    />
    <div class="dsr total">
      <div class="dsr__col">{{ texts[6].label }}</div>
      <div class="dsr__col"></div>
      <div class="dsr__col">{{ totalLiquidity }}</div>
      <div class="dsr__col">$ {{ formatPrice(totalVolume, 0) }}</div>
      <div class="dsr__col">{{ formatPrice(totalTrades, 0) }}</div>
      <div class="dsr__col"></div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  sources: Array,
  labels: Array
})
const textsStore = useTextsStore()
const texts = computed(() => {
  return textsStore.list.price_feeds.sources_labels
})

const groupedPairs = computed(() => {
  const pairs = props.sources.reduce((acc, source) => {
    if (!acc[source.Exchange]) {
      acc[source.Exchange] = []
    }
    acc[source.Exchange].push(source)
    return acc
  }, {})
  return pairs
})

const totalLiquidity = computed(() => {
  const total = props.sources.reduce((acc, source) => {
    return acc + source.PoolLiquidityUSD
  }, 0)
  if (total === 0) {
    return '-'
  }
  return `$ ${formatPrice(total)}`
})

const totalVolume = computed(() => {
  return props.sources.reduce((acc, source) => {
    return acc + source.Volume
  }, 0)
})

const totalTrades = computed(() => {
  return props.sources.reduce((acc, source) => {
    return acc + source.TradesCount
  }, 0)
})

const opened = ref(false)
</script>

<style lang="scss" scoped>
.data-sources {
  margin-top: 20px;

  @media (max-width: 1200px) {
    overflow-x: auto;
  }

  :deep(.dsr) {
    width: 100%;
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 19% 16% 16% 16% 16% auto;
    align-items: center;
    align-content: center;
    padding: 10px 17px 8px;
    border: 1px solid var(--bubble-10, rgba(0, 0, 0, 0.1));
    background: var(--Data-sources, #fff);
    font-size: 1.3rem;
    font-weight: 500;

    &:hover {
      background: var(--bubble-10, rgba(0, 0, 0, 0.1));
    }

    @media (max-width: 1200px) {
      min-width: 1000px;
    }

    &.total {
      background: none;
      border: 0;
    }
    &.header {
      border-radius: 10px;
      background: var(--Banner-5, rgba(0, 0, 0, 0.05));
      font-size: 1.3rem;
      margin-bottom: 5px;
      font-weight: 500;
      color: var(--Text-50, rgba(0, 0, 0, 0.5));
    }

    .dsr__col {
      position: relative;
      z-index: 2;
      text-align: right;
      padding-top: 2px;

      img {
        margin-top: -2px;
      }

      &:first-child {
        text-align: left;
      }
    }

    &.data-row {
      padding: 12px 20px;
      min-height: 50px;
      margin-bottom: 5px;
      border-radius: 10px;
      position: relative;
      overflow: hidden;
      transition: border-radius 0.3s, background-color 0.3s;

      .source {
        display: flex;
        align-items: center;
        gap: 14px;
        font-weight: 700;

        .asset-icons {
          position: relative;
          img {
            display: block;
          }
        }

        .second-icon {
          position: absolute;
          bottom: -2px;
          right: -2px;
        }
      }

      .pairs-btn {
        padding: 7px 10px;
        display: inline-flex;
        align-items: center;
        align-content: center;
        gap: 10px;
        border-radius: 10px;
        transition: background-color 0.3s;
        background: var(--Banner-5, rgba(0, 0, 0, 0.05));
        line-height: 100%;

        .opened & {
          background: var(--banner-10, rgba(0, 0, 0, 0.1));
        }

        .chevron {
          transition: transform 0.3s;
          transform: rotate(180deg);
          height: 5px;
          position: relative;
          top: -1px;

          svg {
            width: 10px;
            height: 5px;
            display: block;

            path {
              stroke: var(--Text, #000);
            }
          }
        }
      }

      &.opened {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        background: var(--bubble-10, rgba(0, 0, 0, 0.1));

        .pairs-btn {
          background: var(--banner-10, rgba(0, 0, 0, 0.1));

          .chevron {
            transform: rotate(0deg);
          }
        }
      }
    }

    &.data-pair-row {
      border-radius: 0;
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      margin-bottom: 0;

      background: var(--Data-sources, #fff);

      &:last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
}
</style>

<template>
  <div class="dropdown-nav" :class="{ 'nav-opened': opened }" ref="refNav">
    <button
      type="button"
      class="opener"
      @click.prevent.stop="opened = !opened"
      v-if="props.openerType === 'dots'"
    >
      <div class="dot dot--1"></div>
      <div class="dot dot--1"></div>
      <div class="dot dot--1"></div>
    </button>
    <button
      type="button"
      class="chevron-opener"
      @click.prevent.stop="opened = !opened"
      v-else-if="props.openerType === 'chevron'"
      v-html="ChevronSvg"
    ></button>
    <div class="nav" @click.stop="opened = false">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import ChevronSvg from '~/layers/base/assets/icons/chevron-up.svg?raw'

const props = defineProps({
  openerType: {
    type: String,
    default: 'dots'
  },
  forceOpen: {
    type: Boolean,
    default: false
  }
})

const opened = ref(false)

const refNav = ref(null)

onClickOutside(refNav, () => {
  opened.value = false
})

const emit = defineEmits(['stateUpdate'])

watch(
  () => props.forceOpen,
  (value) => {
    opened.value = value
  }
)

watch(
  () => opened.value,
  (value) => {
    emit('stateUpdate', value)
  }
)
</script>

<style lang="scss" scoped>
.dropdown-nav {
  position: relative;
  z-index: 9;
}
.opener {
  width: 30px;
  height: 30px;
  position: relative;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
  transition: background-color 0.2s;

  &:hover {
    background: var(--Banner-5, rgba(255, 255, 255, 0.05));
  }

  .nav-opened & {
    background: var(--Block-bg, #fff);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.1);
    border-radius: 5px 5px 0px 0px;
  }

  .dot {
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background: var(--Text, #000000);
  }
}

.chevron-opener {
  width: 15px;
  height: 15px;
  position: relative;
  left: 5px;
  top: -2px;
  transform: rotate(180deg);
  transform-origin: center center;
  transition: transform 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;

  :deep(svg) {
    width: 10px;

    path {
      stroke: var(--Text, #000000);
    }
  }

  .nav-opened & {
    transform: rotate(0);
  }
}

.nav {
  position: absolute;
  right: 0;
  top: 100%;
  background: var(--Block-bg, #fff);
  backdrop-filter: blur(20px);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  min-width: 230px;
  border-radius: 10px 0px 10px 10px;
  padding: 5px;
  pointer-events: none;
  opacity: 0;
  transform: translateY(-10px);
  transition:
    opacity 0.2s,
    transform 0.2s;

  :deep(hr) {
    border: 0;
    border-top: 1px solid var(--bubble-10, rgba(255, 255, 255, 0.1));
    margin-block: 5px;
  }

  .nav-opened & {
    pointer-events: auto;
    opacity: 1;
    transform: translateY(0);
  }
}
</style>

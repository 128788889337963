<template>
  <div class="updates-row update">
    <div class="col">$ {{ assetPrice }}</div>
    <div class="col">
      <a :href="urlTransaction" target="_blank" class="time-link"
        >{{ updateAgo }} <span v-html="TopRightSvg"></span
      ></a>
    </div>
    <div class="col mobile-hide">
      {{ useDateFormat(props.update?.UpdateTime, 'YYYY-MM-DD').value }}
      <time>{{
        useDateFormat(props.update?.UpdateTime, 'HH:mm:ss').value
      }}</time>
    </div>
    <div class="col mobile-hide" v-if="!props.readMode">
      <a :href="urlTransaction" target="_blank" class="tx-link">{{
        shortenAddress(props.update?.TransactionHash)
      }}</a>
    </div>
    <div class="col mobile-hide" v-if="!props.readMode">
      {{
        parseFloat(
          props.update?.TransactionCost / 1000000000000000000
        ).toLocaleString('en-Us', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 10
        })
      }}

      <!-- TODO: Kamil - add currency -->
    </div>
  </div>
</template>

<script setup>
import TopRightSvg from '~/assets/icons/top-right-arrow.svg?raw'
const props = defineProps({
  update: Object,
  readMode: {
    type: Boolean,
    default: false
  }
})

const assetPrice = computed(() => {
  return formatPrice(props.update?.AssetPrice / 100000000)
})

const updateAgo = computed(() => {
  return getTimeAgo(props.update?.UpdateTime)
})

const urlTransaction = computed(() => {
  // TODO: Kamil - add url for transaction
  return ``
})
</script>

<style lang="scss" scoped>
time {
  opacity: 0.5;
}

.col {
  &:first-child {
    font-weight: 500;
  }
}

.time-link {
  @media (min-width: 1024px) {
    text-decoration: none !important;
    pointer-events: none;
    span {
      display: none;
    }
  }

  @media (max-width: 1023px) {
    display: inline-flex;
    align-items: center;
    gap: 8px;
  }
}
</style>

import { defineStore } from 'pinia'

export const useNewOraclesStore = defineStore('newOraclesStore', () => {
  const config = useRuntimeConfig()

  // refs

  const oracles = ref(null)
  const editedOracle = ref(null)

  const loadOraclesSignature = ref(null)
  const feedAggregations = ref({})
  const selectedFeed = ref({})
  const showSelectFeed = ref(false)
  const showFeedDetails = ref(false)
  const selectedOraclesTab = ref('ecosystem')
  const topUpTXHash = ref(null)

  // actions

  async function loadOracles(signature, chainId, walletAddress) {
    console.log('loadOracles', signature, loadOraclesSignature.value)
    if (!loadOraclesSignature.value) loadOraclesSignature.value = signature

    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        Authorization: `Bearer ${signature}`
      }
    }

    const url = `${config.public.oracleApiBaseURL}/oraclebuilder-prod/list?creator=${walletAddress}&chainID=${chainId}`

    try {
      const response = await fetch(url, requestOptions)

      const responseJson = await response.json()

      if (!responseJson) {
        console.error('Error on loadOracles', response)
        return
      }

      oracles.value = responseJson.filter((oracle) => {
        return !oracle.Expired
      })
    } catch (error) {
      console.error('Error on loadOracles', error)
    }
  }

  // getters

  function getFeedTotalVolume(address, chainId, feed) {
    return feedAggregations.value[
      address + '-' + chainId + '-' + feed
    ]?.GetFeedAggregation?.reduce((acc, cur) => acc + cur.Volume, 0)
  }
  function getFeedTotalLiquidity(address, chainId, feed) {
    return feedAggregations.value[
      address + '-' + chainId + '-' + feed
    ]?.GetFeedAggregation?.reduce((acc, cur) => acc + cur.PoolLiquidityUSD, 0)
  }
  function getFeedTotalTrades(address, chainId, feed) {
    return feedAggregations.value[
      address + '-' + chainId + '-' + feed
    ]?.GetFeedAggregation?.reduce((acc, cur) => acc + cur.TradesCount, 0)
  }

  return {
    oracles,
    editedOracle,
    loadOracles,
    loadOraclesSignature,
    feedAggregations,
    getFeedTotalVolume,
    getFeedTotalLiquidity,
    getFeedTotalTrades,
    selectedFeed,
    showSelectFeed,
    showFeedDetails,
    selectedOraclesTab,
    topUpTXHash
  }
})

<template>
  <div>
    <div class="box inner-box">
      <div class="inner-box__title">
        Top up <span class="inner-box__subtitle">via different wallet</span>
      </div>

      <p class="inner-box__text">
        Transfer the funds directly to the oracle's gas wallet. Only deposit
        network-native gas tokens.
      </p>

      <div class="info-label">Gas wallet address</div>
      <div class="address">
        <a :href="`${explorerUrl}/address/${gasWalletAddress}`" target="_blank">
          {{ gasWalletAddress }}
        </a>
        <DeployerUiCopy :copy-value="gasWalletAddress" />
      </div>

      <div class="details">
        <!-- TODO: Kamil - get real values -->
        <div class="token">
          <div class="info-label">Gas token</div>
          <div class="icon-label">
            <img :src="getTokenIconUrl('SOL')" width="17" height="17" />
            Ether
          </div>
        </div>
        <div class="recommend">
          <div class="info-label">Recommended top up</div>
          <div class="icon-label">
            1,00
            <img :src="getTokenIconUrl('SOL')" width="17" height="17" />
            <span class="additional-info">~3.8k updates</span>
          </div>
        </div>
      </div>
    </div>

    <button
      type="button"
      class="btn cta w100"
      @click.prevent="emit('transferred')"
    >
      I already transferred
    </button>
  </div>
</template>

<script setup>
const emit = defineEmits(['transferred'])

//TODO: Kamil -  change to real gas wallet address and explorerUrl
const gasWalletAddress = '0xa93546947f3015c986695750b8bbEa8e26D65856'
const explorerUrl = ''
</script>

<style lang="scss" scoped>
.address {
  text-decoration: underline;
  letter-spacing: 0.01em;
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 30px;
}

.details {
  display: flex;
  justify-content: space-between;
}

.recommend {
  text-align: right;
}

.inner-box {
  margin-bottom: 30px;
}
</style>

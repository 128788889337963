import { useRequestHeaders } from '#app/composables'
import { useWhitelabelStore } from '~/stores/whitelabel'

export default defineNuxtRouteMiddleware(async () => {
  if (process.server) {
    const host = useRequestHeaders(['host']).host

    /* 
      Below will be used for the future more complex logic
      For now save only host to the store to create getter isWhitelabel purpose 
    */

    // const config = useRuntimeConfig();

    // const defaultHost = config.public.defaultHost;

    // if (host.endsWith(defaultHost)) {
    //   const subdomain = host.split('.')[0];
    //   if (subdomain && subdomain !== defaultHost.split('.')[0]) {
    //     // Whitelabel subdomain logic
    //   } else {
    //     // default logic
    //   }
    // } else {
    //   // Custom domain logic
    // }

    const store = useWhitelabelStore()
    store.setWhitelabelHost(host)

    // if (store.isWhitelabel) {
    await store.fetchWhitelabelData(host)
    // }
  }
})

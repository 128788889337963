<template>
  <div>
    {{ whiteLabelStore.paid_plans }}
    <DeployerUiPlans
      v-if="whiteLabelStore.config?.paid_plans"
      :whitelabel-plans="whiteLabelStore.config?.paid_plans"
    />
    <div class="mt30 buttons-row buttons-row--mobile-column">
      <button type="button" class="btn cta" @click.prevent="selectPlan">
        {{ textsStore.account.select_plan_modal.select_button_label }}
      </button>
      <button
        type="button"
        class="btn transparent ml5"
        @click.prevent="modalStore.closeModal"
      >
        {{ textsStore.account.select_plan_modal.later_button_label }}
      </button>
    </div>
  </div>
</template>

<script setup>
const accountStore = useAccountStore()
const modalStore = useModalStore()
const textsStore = useTextsStore()
const whiteLabelStore = useWhitelabelStore()

const selectPlan = () => {
  console.log('Select plan')
}
</script>

<style scoped lang="scss"></style>

<template>
  <div>
    <header class="header">
      <div class="header__inner container">
        <NuxtLink to="/" class="logo">
          <img
            :src="logo"
            :height="mainStore.logo.height"
            :alt="mainStore.logo.alt"
          />
          <span class="divider">|</span><span>Oracle Builder</span>
        </NuxtLink>
        <div v-if="newWalletStore?.account?.address" class="buttons">
          <NuxtLink
            to="/deploy/"
            class="btn add-oracle-btn"
            :class="{
              cta:
                accountStore?.account?.plan?.plan_id !== 0 &&
                accountStore?.account !== null,
              'cta-border':
                accountStore?.account?.plan?.plan_id === 0 ||
                accountStore?.account === null
            }"
            v-if="route.name !== 'deploy'"
          >
            <span v-html="AddOracleSvg"></span> New oracle
          </NuxtLink>

          <NuxtLink
            v-if="accountStore?.account?.plan?.plan_id"
            to="/account/plan/"
            class="btn grey-border plan-btn"
          >
            <div class="tag">{{ accountStore.account.plan.plan_name }}</div>
            <div>
              <strong>{{ accountStore.account.number_of_data_feeds }}</strong
              >/{{ accountStore.account.plan.total_feeds }} feeds
            </div>
          </NuxtLink>
          <NuxtLink v-else class="btn cta" to="/account/plan/"
            >Select plan to deploy</NuxtLink
          >
          <div
            role="button"
            class="btn grey-border account-btn"
            @click.prevent="changeDropdownState"
          >
            {{ newWalletStore.getWalletAddress() }}
            <DeployerUiDropdownNav
              opener-type="chevron"
              :force-open="openDropdown"
              @state-update="updateDrodownState"
            >
              <DeployerUiDropdownNavButton
                :icon-svg="AccountUserSvg"
                label="Account settings"
                @click.prevent="navigateTo('/account/')"
              />
              <div class="dm-wrapper">
                <DeployerUiDropdownNavButton
                  :icon-svg="DarkModeSvg"
                  label="Dark mode"
                  @click.prevent.stop="toggleColorMode"
                />
                <DeployerUiSwitch
                  class="dm-switch"
                  :is-active="colorMode === 'dark'"
                />
              </div>

              <hr />
              <DeployerUiDropdownNavButton
                :icon-svg="LogoutSvg"
                label="Log out"
                @click.prevent="logout"
              />
            </DeployerUiDropdownNav>
          </div>
        </div>
        <button
          v-else
          class="btn cta"
          @click="newWalletStore.walletConnectModal.open()"
        >
          Log in
        </button>
      </div>
    </header>

    <DeployerUiMobileBar />
  </div>
</template>

<script setup>
import AddOracleSvg from '~/layers/base/assets/icons/add-oracle.svg?raw'
import AccountSvg from '~/layers/base/assets/icons/account.svg?raw'
import AccountUserSvg from '~/layers/base/assets/icons/account-user.svg?raw'
import DarkModeSvg from '~/layers/base/assets/icons/dark-mode.svg?raw'
import LogoutSvg from '~/layers/base/assets/icons/logout.svg?raw'

const mainStore = useMainStore()
const whitelabelStore = useWhitelabelStore()
const newWalletStore = useNewWalletStore()
const accountStore = useAccountStore()
const route = useRoute()

const colorMode = useCookie('colorMode')
const openDropdown = ref(false)

const logo = computed(() => {
  if (colorMode.value === 'dark') {
    return whitelabelStore.config.logo_dark || whitelabelStore.config.logo
  } else {
    return whitelabelStore.config?.logo
  }
})

const logout = () => {
  newWalletStore.logout()
}

watch(
  () => newWalletStore?.account?.address,
  async (newAddress) => {
    if (newAddress) {
      await accountStore.viewDashboard()
    }
  }
)

const toggleColorMode = () => {
  console.log('aaa')
  if (colorMode.value === 'dark') {
    colorMode.value = 'light'
    whitelabelStore.setCssVars('light')
  } else {
    colorMode.value = 'dark'
    whitelabelStore.setCssVars('dark')
  }
}

const changeDropdownState = () => {
  if (!openDropdown.value) {
    openDropdown.value = true
  }
}

const updateDrodownState = (value) => {
  setTimeout(() => {
    openDropdown.value = value
  }, 100)
}

onMounted(async () => {
  if (newWalletStore?.account?.address) {
    await accountStore.viewDashboard()
  }
})
</script>

<style lang="scss" scoped>
.header {
  padding-block: 15px;
  background: rgba(255, 255, 255, 0.1);

  backdrop-filter: blur(40px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 49;

  @media (max-width: 1024px) {
    padding-top: 10px;
    padding-bottom: 4px;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 100dvh;
      background: #000;
      opacity: 0;
      pointer-events: none;
      transition:
        opacity 0.3s,
        transform 0.3s;
    }
    .price-feed-opened & {
      &::after {
        opacity: 1;
        pointer-events: all;
      }
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  :deep(.nb__icon) {
    opacity: 0.5;
  }

  :deep(.dropdown-nav) {
    .nav {
      width: 260px;
      top: calc(100% + 20px);
      right: -20px;
      border-radius: 10px;
      border: 1px solid var(--field-stroke);
      background: var(--Page-bg, #fff);
    }
  }
}

.logo {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 1.9rem;

  :deep(img) {
    @media (max-width: 800px) {
      max-width: 75px;
      height: auto;
    }
  }

  @media (max-width: 800px) {
    font-size: 1.5rem;
  }

  .divider {
    @media (max-width: 800px) {
      display: none;
    }
  }
}

.buttons {
  display: flex;
  gap: 10px;
  align-items: center;

  @media (max-width: 1024px) {
    display: none;
  }

  .btn {
    height: 42px;
    display: flex;
    align-items: center;
    font-weight: 400 !important;
    text-transform: none;
    font-family: var(--font-main);
    letter-spacing: 0;
    font-size: 1.6rem;
    line-height: 120%;
  }
  .add-oracle-btn {
    display: flex;
    gap: 10px;
    align-items: center;
    :deep(svg) {
      position: relative;
      top: -1px;
    }

    &.cta-border {
      :deep(path) {
        stroke: var(--CTA);
      }

      &:hover {
        :deep(path) {
          stroke: var(--CTA-text);
        }
      }
    }

    @media (max-width: 800px) {
      font-size: 0;
      padding: 0;
      gap: 0;
      background: transparent;

      :deep(svg) {
        width: 22px;
        height: 22px;
        path {
          stroke: var(--Text, #000);
          stroke-width: 1px;
        }
      }
    }
  }
  .plan-btn {
    display: flex;
    gap: 15px;

    @media (max-width: 800px) {
      display: none;
    }

    .tag {
      background: #e1dcff;
      color: #654cff;
      font-weight: 700;
      padding: 2px 4px 1px 4px;
      border-radius: 5px;
    }
  }

  .account-btn {
    color: var(--Text, #000);
    cursor: pointer;
    @media (max-width: 800px) {
      font-size: 0;
      padding: 0;
      gap: 0;
      border: 0;

      :deep(svg) {
        width: 20px;
        height: 20px;
        position: relative;
        top: -1px;
        path {
          stroke: var(--Text, #000);
          stroke-width: 1px;
        }
      }
    }

    @media (min-width: 801px) {
      :deep(svg) {
        // display: none;
      }
    }
  }
}

.dm-wrapper {
  position: relative;
}

.dm-switch {
  position: absolute;
  right: 15px;
  top: 10px;
  pointer-events: none;
}
</style>

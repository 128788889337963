<template>
  <div class="notice-container">
    <span class="icon" v-html="NoticeSvg"></span>
    <span v-html="props.notice"></span>
  </div>
</template>

<script setup>
import NoticeSvg from '~/layers/base/assets/icons/notice.svg?raw'

const props = defineProps({
  notice: String
})
</script>

<style lang="scss" scoped>
.notice-container {
  border: 1px solid var(--field-stroke-hover, rgba(0, 0, 0, 0.4));
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 1.4rem;
  letter-spacing: 0.01em;
  margin-top: 30px;
  color: var(--Text, #000);
  display: flex;
  align-items: center;
  gap: 15px;
}

.icon {
  :deep(svg) {
    path {
      fill: var(--Text, #000);
    }
  }
}
</style>

<template>
  <div class="inner-box">
    <div class="inner-box__title">
      Top up executed
      <span class="inner-box__subtitle">via different wallet</span>
    </div>

    <p class="inner-box__text">
      Top up executed via different wallet It might take few minutes until the
      refill takes effect in the dashboard.
    </p>

    <button type="button" class="btn cta w100" @click="modalStore.closeModal">
      Go to manage dashboard
    </button>
  </div>
</template>

<script setup>
const modalStore = useModalStore()
</script>

<style lang="scss" scoped></style>

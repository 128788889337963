<template>
  <div class="switch" :class="{ active: props.isActive }">
    <div class="dot"></div>
  </div>
</template>

<script setup>
const props = defineProps({
  isActive: Boolean
})
</script>

<style lang="scss" scoped>
.switch {
  height: 22px;
  width: 40px;
  border-radius: 11px;
  background: var(--field-stroke, rgba(0, 0, 0, 0.2));
  padding: 2px;
  display: flex;
  cursor: pointer;

  &.active {
    background: linear-gradient(
      103deg,
      var(--CTA) -37.86%,
      var(--Secondary-CTA) 55.88%
    );
  }
}

.dot {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #fff;
  margin-left: 0;
  transition: 0.2s margin;

  .active & {
    margin-left: calc(100% - 18px);
  }
}
</style>

<template>
  <div
    class="updates-wrapper"
    :class="{ 'read-mode': newOraclesStore.selectedFeed.readMode }"
  >
    <div class="updates-row header">
      <div class="col">{{ texts.updates_labels[0].label }}</div>
      <div class="col">{{ texts.updates_labels[1].label }}</div>
      <div class="col mobile-hide">{{ texts.updates_labels[2].label }}</div>
      <div
        class="col mobile-hide"
        v-if="!newOraclesStore.selectedFeed.readMode"
      >
        {{ texts.updates_labels[3].label }}
      </div>
      <div
        class="col mobile-hide"
        v-if="!newOraclesStore.selectedFeed.readMode"
      >
        {{ texts.updates_labels[4].label }}
      </div>
    </div>

    <DeployerFeedUpdatesItem
      v-for="update in updates"
      :update="update"
      :read-mode="newOraclesStore.selectedFeed.readMode"
      :key="`ui-${update.TransactionHash}`"
    />
    <div class="loading" v-if="updates.length === 0">
      <img src="/images/loading.gif" alt="loading" width="30" height="30" />
    </div>
  </div>
  <div class="load-more" :class="{ loading }">
    <button
      type="button"
      class="btn grey-border"
      @click.prevent="loadUpdates"
      v-if="currentPage < maxPage"
    >
      {{ texts.load_more }}
    </button>
  </div>
</template>

<script setup>
const newOraclesStore = useNewOraclesStore()

const textsStore = useTextsStore()
const texts = computed(() => {
  return textsStore.list.price_feeds
})

const currentPage = ref(1)
const loading = ref(true)
const updatesCount = ref(0)
const updates = ref([])
const maxPage = computed(() => Math.ceil(updatesCount.value / 20))
const isLoadMoreVisible = ref(false)
const intervalKeeper = ref(null)

const loadUrl = computed(() => {
  return `https://api.diadata.org/oraclebuilder-prod/stats?address=${newOraclesStore.selectedFeed.address}&chainID=${newOraclesStore.selectedFeed.chainId}&symbol=${newOraclesStore.selectedFeed.symbol}/USD&page=${currentPage.value}`
})

const loadUpdates = async () => {
  loading.value = true
  const historyMore = await $fetch(loadUrl.value)
  updatesCount.value = historyMore?.Count
  updates.value = [...updates.value, ...historyMore.Updates]

  currentPage.value++
  loading.value = false
}

loadUpdates()
</script>

<style lang="scss" scoped>
.updates-wrapper {
  border-radius: 10px;
  border: 1px solid var(--bubble-10, rgba(0, 0, 0, 0.1));
  background: var(--Data-sources, #fff);

  :deep(.updates-row) {
    display: grid;
    grid-template-columns: 20% 16% 26% 18% auto;
    text-align: right;
    font-size: 1.3rem;
    color: var(--Text, #000);

    @media (max-width: 1023px) {
      grid-template-columns: 50% auto;
    }

    &.header {
      border-radius: 10px 10px 0px 0px;
      border-bottom: 1px solid var(--bubble-10, rgba(0, 0, 0, 0.1));
      background: var(--Banner-5, rgba(0, 0, 0, 0.05));
      padding: 15px 17px 12px;
      color: var(--Text-50, rgba(0, 0, 0, 0.5));
    }

    &.updates-row {
      padding: 15px 17px 12px;
      border-bottom: 1px solid var(--bubble-10, rgba(0, 0, 0, 0.1));

      &:last-child {
        border-bottom: 0;
      }
    }

    .col {
      &:first-child {
        text-align: left;
      }
    }

    a {
      text-decoration: underline;
    }
  }

  :deep(.mobile-hide) {
    @media (max-width: 1023px) {
      display: none;
    }
  }

  &.read-mode {
    :deep(.updates-row) {
      grid-template-columns: 26% 26% auto;
    }
  }
}

.load-more {
  margin-top: 20px;
  text-align: center;
  position: relative;

  &.loading {
    pointer-events: none;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: url(/images/loading.gif) no-repeat center center;
      background-size: 20px;
    }
    .btn {
      opacity: 0;
    }
  }
}

.loading {
  text-align: center;
}
</style>

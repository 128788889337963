import { defineStore } from 'pinia'

export const useOraclesStore = defineStore('oraclesStore', {
  state: () => {
    return {
      test: 'jbjbjb',
      oracles: {},
      editOracle: {},
      topUpTXHash: '',
      showFeedDetails: false,
      selectedFeed: {},
      showModal: false,
      tourModalSeen: false,
      modalType: 'text',
      modalRedirect: null,
      modalContent: {},
      canCloseModal: true,
      manageChainId: null,
      data: {
        '0x11E405e4432c615315C624159A42584A03DccAdF-11155111-DOGE': {
          GetFeedAggregation: [
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Binance',
              LastPrice: 0.11906100729524753,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDT',
              Quotetokensymbol: 'DOGE',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Dogechain',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 93176641.8703397,
              TradesCount: 125018,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Binance',
              LastPrice: 0.11898096728520334,
              Basetokenaddress: '0xc5f0f7b66764F6ec8C8Dff7BA683102295E16409',
              Basetokenblockchain: 'BinanceSmartChain',
              Basetokensymbol: 'FDUSD',
              Quotetokensymbol: 'DOGE',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Dogechain',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 75367962.64432976,
              TradesCount: 200640,
              Time: '2024-06-24T06:29:05Z'
            }
          ]
        },
        '0x95aFDA4fa7CE7701e5394a53A3790B861dD6E4Bf-11155111-BNB': {
          GetFeedAggregation: [
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Binance',
              LastPrice: 567.9614109295354,
              Basetokenaddress: '0xc5f0f7b66764F6ec8C8Dff7BA683102295E16409',
              Basetokenblockchain: 'BinanceSmartChain',
              Basetokensymbol: 'FDUSD',
              Quotetokensymbol: 'BNB',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'BinanceSmartChain',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 123946362.18269737,
              TradesCount: 85685,
              Time: '2024-06-24T06:29:07Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Binance',
              LastPrice: 568.5480681056766,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDT',
              Quotetokensymbol: 'BNB',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'BinanceSmartChain',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 108196869.89633189,
              TradesCount: 188832,
              Time: '2024-06-24T06:29:07Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Binance',
              LastPrice: 568.0693888080275,
              Basetokenaddress: '0x0000000000000000000000000000000000000000',
              Basetokenblockchain: 'Bitcoin',
              Basetokensymbol: 'BTC',
              Quotetokensymbol: 'BNB',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'BinanceSmartChain',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 17777140.91679219,
              TradesCount: 89773,
              Time: '2024-06-24T06:29:07Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'ByBit',
              LastPrice: 568.34801600571,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDT',
              Quotetokensymbol: 'BNB',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'BinanceSmartChain',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 10512384.983520664,
              TradesCount: 47980,
              Time: '2024-06-24T06:29:07Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'GateIO',
              LastPrice: 568.5480681056766,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDT',
              Quotetokensymbol: 'BNB',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'BinanceSmartChain',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 5575278.490036986,
              TradesCount: 15797,
              Time: '2024-06-24T06:29:07Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Binance',
              LastPrice: 568.4042254960722,
              Basetokenaddress: '0x0000000000000000000000000000000000000000',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'ETH',
              Quotetokensymbol: 'BNB',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'BinanceSmartChain',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 1073126.2462403276,
              TradesCount: 3581,
              Time: '2024-06-24T06:29:07Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'KuCoin',
              LastPrice: 569.1815094737887,
              Basetokenaddress: '0x0000000000000000000000000000000000000000',
              Basetokenblockchain: 'Bitcoin',
              Basetokensymbol: 'BTC',
              Quotetokensymbol: 'BNB',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'BinanceSmartChain',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 20475.375433767145,
              TradesCount: 202,
              Time: '2024-06-24T06:29:07Z'
            }
          ]
        },
        '0x95aFDA4fa7CE7701e5394a53A3790B861dD6E4Bf-11155111-SOL': {
          GetFeedAggregation: [
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Binance',
              LastPrice: 124.67957675941271,
              Basetokenaddress: '0xc5f0f7b66764F6ec8C8Dff7BA683102295E16409',
              Basetokenblockchain: 'BinanceSmartChain',
              Basetokensymbol: 'FDUSD',
              Quotetokensymbol: 'SOL',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Solana',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 249955242.31306383,
              TradesCount: 441191,
              Time: '2024-06-24T06:29:07Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'ByBit',
              LastPrice: 124.75248953930331,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDT',
              Quotetokensymbol: 'SOL',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Solana',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 100512823.96716003,
              TradesCount: 274339,
              Time: '2024-06-24T06:29:07Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Bitmax',
              LastPrice: 125.10218061004528,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDT',
              Quotetokensymbol: 'SOL',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Solana',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 7112323.879935597,
              TradesCount: 22086,
              Time: '2024-06-24T06:29:07Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Crypto.com',
              LastPrice: 124.78249735429833,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDT',
              Quotetokensymbol: 'SOL',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Solana',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 3269186.1263165046,
              TradesCount: 13011,
              Time: '2024-06-24T06:29:07Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Bitfinex',
              LastPrice: 124.8225077742917,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'UST',
              Quotetokensymbol: 'SOL',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Solana',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 1736430.9503190503,
              TradesCount: 3731,
              Time: '2024-06-24T06:29:07Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'BitMex',
              LastPrice: 124.60245046432819,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDT',
              Quotetokensymbol: 'SOL',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Solana',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 1735118.1275993902,
              TradesCount: 3300,
              Time: '2024-06-24T06:29:07Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Bitstamp',
              LastPrice: 124.7021,
              Basetokenaddress: '840',
              Basetokenblockchain: 'Fiat',
              Basetokensymbol: 'USD',
              Quotetokensymbol: 'SOL',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Solana',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 1166590.332743999,
              TradesCount: 2012,
              Time: '2024-06-24T06:29:07Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Crypto.com',
              LastPrice: 124.74235833384783,
              Basetokenaddress: '0x0000000000000000000000000000000000000000',
              Basetokenblockchain: 'Bitcoin',
              Basetokensymbol: 'BTC',
              Quotetokensymbol: 'SOL',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Solana',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 60491.95805763452,
              TradesCount: 4820,
              Time: '2024-06-24T06:29:07Z'
            }
          ]
        },
        '0x95aFDA4fa7CE7701e5394a53A3790B861dD6E4Bf-11155111-DOGE': {
          GetFeedAggregation: [
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Binance',
              LastPrice: 0.1191010177152409,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDT',
              Quotetokensymbol: 'DOGE',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Dogechain',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 93177763.33156408,
              TradesCount: 125066,
              Time: '2024-06-24T06:29:07Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Binance',
              LastPrice: 0.11903095508760868,
              Basetokenaddress: '0xc5f0f7b66764F6ec8C8Dff7BA683102295E16409',
              Basetokenblockchain: 'BinanceSmartChain',
              Basetokensymbol: 'FDUSD',
              Quotetokensymbol: 'DOGE',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Dogechain',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 75364013.79289116,
              TradesCount: 200640,
              Time: '2024-06-24T06:29:07Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'ByBit',
              LastPrice: 0.1190309994802525,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDT',
              Quotetokensymbol: 'DOGE',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Dogechain',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 29624965.816874105,
              TradesCount: 92435,
              Time: '2024-06-24T06:29:07Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'CoinBase',
              LastPrice: 0.11898,
              Basetokenaddress: '840',
              Basetokenblockchain: 'Fiat',
              Basetokensymbol: 'USD',
              Quotetokensymbol: 'DOGE',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Dogechain',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 27034608.18548594,
              TradesCount: 54917,
              Time: '2024-06-24T06:29:07Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Bitmax',
              LastPrice: 0.11913102553023591,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDT',
              Quotetokensymbol: 'DOGE',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Dogechain',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 3346527.2648436157,
              TradesCount: 13310,
              Time: '2024-06-24T06:29:07Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Crypto.com',
              LastPrice: 0.11903199974075235,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDT',
              Quotetokensymbol: 'DOGE',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Dogechain',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 1719395.5222085267,
              TradesCount: 8043,
              Time: '2024-06-24T06:29:07Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Bitmax',
              LastPrice: 0.11932840417915394,
              Basetokenaddress: '0x0000000000000000000000000000000000000000',
              Basetokenblockchain: 'Bitcoin',
              Basetokensymbol: 'BTC',
              Quotetokensymbol: 'DOGE',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Dogechain',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 517229.1008807233,
              TradesCount: 2707,
              Time: '2024-06-24T06:29:07Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Bitstamp',
              LastPrice: 0.11903,
              Basetokenaddress: '840',
              Basetokenblockchain: 'Fiat',
              Basetokensymbol: 'USD',
              Quotetokensymbol: 'DOGE',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Dogechain',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 162439.33776489997,
              TradesCount: 571,
              Time: '2024-06-24T06:29:07Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Crypto.com',
              LastPrice: 0.11897572542400096,
              Basetokenaddress: '0x0000000000000000000000000000000000000000',
              Basetokenblockchain: 'Bitcoin',
              Basetokensymbol: 'BTC',
              Quotetokensymbol: 'DOGE',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Dogechain',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 111697.58366243908,
              TradesCount: 4661,
              Time: '2024-06-24T06:29:07Z'
            }
          ]
        },
        '0x554DBd9801e8F65feD6A64F0f58F9A863b57ceBa-11155111-ETH': {
          GetFeedAggregation: [
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Binance',
              LastPrice: 3375.236403771814,
              Basetokenaddress: '0xc5f0f7b66764F6ec8C8Dff7BA683102295E16409',
              Basetokenblockchain: 'BinanceSmartChain',
              Basetokensymbol: 'FDUSD',
              Quotetokensymbol: 'ETH',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Ethereum',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 725916852.2070135,
              TradesCount: 483312,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Binance',
              LastPrice: 3377.269549034704,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDT',
              Quotetokensymbol: 'ETH',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Ethereum',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 702978661.4605747,
              TradesCount: 363613,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'ByBit',
              LastPrice: 3377.3195620596957,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDT',
              Quotetokensymbol: 'ETH',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Ethereum',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 591436274.1748685,
              TradesCount: 1020293,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Binance',
              LastPrice: 3375.2862172105997,
              Basetokenaddress: '0x0000000000000000000000000000000000000000',
              Basetokenblockchain: 'Bitcoin',
              Basetokensymbol: 'BTC',
              Quotetokensymbol: 'ETH',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Ethereum',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 83450675.18606794,
              TradesCount: 91172,
              Time: '2024-06-24T06:29:05Z'
            }
          ]
        },
        '0x11E405e4432c615315C624159A42584A03DccAdF-11155111-ETH': {
          GetFeedAggregation: [
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Binance',
              LastPrice: 3375.236403771814,
              Basetokenaddress: '0xc5f0f7b66764F6ec8C8Dff7BA683102295E16409',
              Basetokenblockchain: 'BinanceSmartChain',
              Basetokensymbol: 'FDUSD',
              Quotetokensymbol: 'ETH',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Ethereum',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 725916852.2070135,
              TradesCount: 483312,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Binance',
              LastPrice: 3377.269549034704,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDT',
              Quotetokensymbol: 'ETH',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Ethereum',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 702978661.4605747,
              TradesCount: 363613,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'ByBit',
              LastPrice: 3377.3195620596957,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDT',
              Quotetokensymbol: 'ETH',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Ethereum',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 591436274.1748685,
              TradesCount: 1020293,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Binance',
              LastPrice: 3375.2862172105997,
              Basetokenaddress: '0x0000000000000000000000000000000000000000',
              Basetokenblockchain: 'Bitcoin',
              Basetokensymbol: 'BTC',
              Quotetokensymbol: 'ETH',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Ethereum',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 83450675.18606794,
              TradesCount: 91172,
              Time: '2024-06-24T06:29:05Z'
            }
          ]
        },
        '0x1fF75b73d8FD74de35029115E2e6d193de2c7440-11155111-ETH': {
          GetFeedAggregation: [
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Binance',
              LastPrice: 3377.269549034704,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDT',
              Quotetokensymbol: 'ETH',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Ethereum',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 702978661.4604986,
              TradesCount: 363613,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'ByBit',
              LastPrice: 3377.3195620596957,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDT',
              Quotetokensymbol: 'ETH',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Ethereum',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 591436274.1748778,
              TradesCount: 1020293,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'GateIO',
              LastPrice: 3377.4595985296723,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDT',
              Quotetokensymbol: 'ETH',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Ethereum',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 268081042.55343398,
              TradesCount: 77211,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Crypto.com',
              LastPrice: 3377.2395412197093,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDT',
              Quotetokensymbol: 'ETH',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Ethereum',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 214676090.0103183,
              TradesCount: 267309,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Bitmax',
              LastPrice: 3376.989476094751,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDT',
              Quotetokensymbol: 'ETH',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Ethereum',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 149377879.34775582,
              TradesCount: 52036,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Binance',
              LastPrice: 3375.2862172105997,
              Basetokenaddress: '0x0000000000000000000000000000000000000000',
              Basetokenblockchain: 'Bitcoin',
              Basetokensymbol: 'BTC',
              Quotetokensymbol: 'ETH',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Ethereum',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 83450675.18606794,
              TradesCount: 91172,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'CoinBase',
              LastPrice: 3383.8839302268443,
              Basetokenaddress: '0x0000000000000000000000000000000000000000',
              Basetokenblockchain: 'Bitcoin',
              Basetokensymbol: 'BTC',
              Quotetokensymbol: 'ETH',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Ethereum',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 15315053.205115192,
              TradesCount: 6672,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Bitstamp',
              LastPrice: 3374.6,
              Basetokenaddress: '840',
              Basetokenblockchain: 'Fiat',
              Basetokensymbol: 'USD',
              Quotetokensymbol: 'ETH',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Ethereum',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 11514805.611498697,
              TradesCount: 5435,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Kraken',
              LastPrice: 3383.8839302268443,
              Basetokenaddress: '0x0000000000000000000000000000000000000000',
              Basetokenblockchain: 'Bitcoin',
              Basetokensymbol: 'BTC',
              Quotetokensymbol: 'ETH',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Ethereum',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 7682775.206027961,
              TradesCount: 1567,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'BitMex',
              LastPrice: 3383.4961281954406,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDT',
              Quotetokensymbol: 'ETH',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Ethereum',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 4404048.454684498,
              TradesCount: 3803,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Bitfinex',
              LastPrice: 3374.9539234871927,
              Basetokenaddress: '0x0000000000000000000000000000000000000000',
              Basetokenblockchain: 'Bitcoin',
              Basetokensymbol: 'BTC',
              Quotetokensymbol: 'ETH',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Ethereum',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 1688717.8012575384,
              TradesCount: 1621,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'KuCoin',
              LastPrice: 3375.2862172105997,
              Basetokenaddress: '0x0000000000000000000000000000000000000000',
              Basetokenblockchain: 'Bitcoin',
              Basetokensymbol: 'BTC',
              Quotetokensymbol: 'ETH',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Ethereum',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 1677617.0504236626,
              TradesCount: 4981,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Bitfinex',
              LastPrice: 3383.1379580328867,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'UST',
              Quotetokensymbol: 'ETH',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Ethereum',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 1361573.227885952,
              TradesCount: 2898,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Crypto.com',
              LastPrice: 3376.531710279681,
              Basetokenaddress: '0x0000000000000000000000000000000000000000',
              Basetokenblockchain: 'Bitcoin',
              Basetokensymbol: 'BTC',
              Quotetokensymbol: 'ETH',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Ethereum',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 908181.2753075579,
              TradesCount: 6594,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Bitmax',
              LastPrice: 3390.624237575876,
              Basetokenaddress: '0x0000000000000000000000000000000000000000',
              Basetokenblockchain: 'Bitcoin',
              Basetokensymbol: 'BTC',
              Quotetokensymbol: 'ETH',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Ethereum',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 437495.09594158013,
              TradesCount: 2711,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Bitstamp',
              LastPrice: 3381.686191189374,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDT',
              Quotetokensymbol: 'ETH',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Ethereum',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 214617.58744155554,
              TradesCount: 251,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'BitMart',
              LastPrice: 3480.916542027509,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDT',
              Quotetokensymbol: 'ETH',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Ethereum',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 202632.08210801383,
              TradesCount: 1257,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'GateIO',
              LastPrice: 3380.385177998599,
              Basetokenaddress: '0x0000000000000000000000000000000000000000',
              Basetokenblockchain: 'Bitcoin',
              Basetokensymbol: 'BTC',
              Quotetokensymbol: 'ETH',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Ethereum',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 174181.9351067912,
              TradesCount: 1781,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Bitstamp',
              LastPrice: 3383.599339472107,
              Basetokenaddress: '0x0000000000000000000000000000000000000000',
              Basetokenblockchain: 'Bitcoin',
              Basetokensymbol: 'BTC',
              Quotetokensymbol: 'ETH',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Ethereum',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 173104.54713715066,
              TradesCount: 440,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Crypto.com',
              LastPrice: 3381.909173653147,
              Basetokenaddress: '0xA0b73E1Ff0B80914AB6fe0444E65848C4C34450b',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'CRO',
              Quotetokensymbol: 'ETH',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Ethereum',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 171453.06561852043,
              TradesCount: 6569,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Bitstamp',
              LastPrice: 3371.997801406656,
              Basetokenaddress: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDC',
              Quotetokensymbol: 'ETH',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Ethereum',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 63622.46227174593,
              TradesCount: 91,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'BitMart',
              LastPrice: 3427.398661520551,
              Basetokenaddress: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'DAI',
              Quotetokensymbol: 'ETH',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Ethereum',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 5362.924630360713,
              TradesCount: 369,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'BitMart',
              LastPrice: 3479.3649410371336,
              Basetokenaddress: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDC',
              Quotetokensymbol: 'ETH',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Ethereum',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 3902.75283866084,
              TradesCount: 274,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'BitMart',
              LastPrice: 3376.8430835469517,
              Basetokenaddress: '0x0000000000000000000000000000000000000000',
              Basetokenblockchain: 'Bitcoin',
              Basetokensymbol: 'BTC',
              Quotetokensymbol: 'ETH',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Ethereum',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 3291.369069900587,
              TradesCount: 175,
              Time: '2024-06-24T06:29:05Z'
            }
          ]
        },
        '0x1fF75b73d8FD74de35029115E2e6d193de2c7440-11155111-BTC': {
          GetFeedAggregation: [
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Binance',
              LastPrice: 62306.37657373825,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDT',
              Quotetokensymbol: 'BTC',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Bitcoin',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 924898271.7869742,
              TradesCount: 766182,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'ByBit',
              LastPrice: 62289.37214524107,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDT',
              Quotetokensymbol: 'BTC',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Bitcoin',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 819106337.8359209,
              TradesCount: 907660,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'CoinBase',
              LastPrice: 62235.65,
              Basetokenaddress: '840',
              Basetokenblockchain: 'Fiat',
              Basetokensymbol: 'USD',
              Quotetokensymbol: 'BTC',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Bitcoin',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 313822784.29792815,
              TradesCount: 245235,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Bitmax',
              LastPrice: 62300.54505502421,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDT',
              Quotetokensymbol: 'BTC',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Bitcoin',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 271948734.6149417,
              TradesCount: 62518,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Crypto.com',
              LastPrice: 62291.22262716576,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDT',
              Quotetokensymbol: 'BTC',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Bitcoin',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 189581011.42578217,
              TradesCount: 156276,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'GateIO',
              LastPrice: 62309.227316162775,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDT',
              Quotetokensymbol: 'BTC',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Bitcoin',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 119768625.06976816,
              TradesCount: 50444,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Kraken',
              LastPrice: 62244.2,
              Basetokenaddress: '840',
              Basetokenblockchain: 'Fiat',
              Basetokensymbol: 'USD',
              Quotetokensymbol: 'BTC',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Bitcoin',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 55940491.068377204,
              TradesCount: 16989,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Bitstamp',
              LastPrice: 62248,
              Basetokenaddress: '840',
              Basetokenblockchain: 'Fiat',
              Basetokensymbol: 'USD',
              Quotetokensymbol: 'BTC',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Bitcoin',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 42033531.3056938,
              TradesCount: 7511,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Bitfinex',
              LastPrice: 62318.22966066128,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'UST',
              Quotetokensymbol: 'BTC',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Bitcoin',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 24829319.455191772,
              TradesCount: 7252,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'BitMex',
              LastPrice: 62282.82043896715,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDT',
              Quotetokensymbol: 'XBT',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Bitcoin',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 24340362.76566994,
              TradesCount: 15016,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Bitstamp',
              LastPrice: 62277.96154870109,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDT',
              Quotetokensymbol: 'BTC',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Bitcoin',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 178057.60222682657,
              TradesCount: 441,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Bitstamp',
              LastPrice: 62299.81038690711,
              Basetokenaddress: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDC',
              Quotetokensymbol: 'BTC',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Bitcoin',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 172229.37966729852,
              TradesCount: 238,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'BitMart',
              LastPrice: 64132.85224643523,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDT',
              Quotetokensymbol: 'BTC',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Bitcoin',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 48765.36543617377,
              TradesCount: 351,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'BitMart',
              LastPrice: 64093.637216822084,
              Basetokenaddress: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDC',
              Quotetokensymbol: 'BTC',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Bitcoin',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 4900.797316341751,
              TradesCount: 1173,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'BitMart',
              LastPrice: 63151.698204878216,
              Basetokenaddress: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'DAI',
              Quotetokensymbol: 'BTC',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Bitcoin',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 1820.9705339929508,
              TradesCount: 131,
              Time: '2024-06-24T06:29:05Z'
            }
          ]
        },
        '0x11E405e4432c615315C624159A42584A03DccAdF-11155111-BTC': {
          GetFeedAggregation: [
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Binance',
              LastPrice: 62260.81764946639,
              Basetokenaddress: '0xc5f0f7b66764F6ec8C8Dff7BA683102295E16409',
              Basetokenblockchain: 'BinanceSmartChain',
              Basetokensymbol: 'FDUSD',
              Quotetokensymbol: 'BTC',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Bitcoin',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 1262339936.5384514,
              TradesCount: 1002338,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'Binance',
              LastPrice: 62306.37657373825,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDT',
              Quotetokensymbol: 'BTC',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Bitcoin',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 924898271.7868233,
              TradesCount: 766182,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'ByBit',
              LastPrice: 62289.37214524107,
              Basetokenaddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
              Basetokenblockchain: 'Ethereum',
              Basetokensymbol: 'USDT',
              Quotetokensymbol: 'BTC',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Bitcoin',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 819106337.8359776,
              TradesCount: 907660,
              Time: '2024-06-24T06:29:05Z'
            },
            {
              __typename: 'FeedSelectionAggregated',
              Exchange: 'CoinBase',
              LastPrice: 62235.65,
              Basetokenaddress: '840',
              Basetokenblockchain: 'Fiat',
              Basetokensymbol: 'USD',
              Quotetokensymbol: 'BTC',
              Quotetokenaddress: '0x0000000000000000000000000000000000000000',
              Quotetokenblockchain: 'Bitcoin',
              Pooladdress: '',
              PoolLiquidityUSD: 0,
              Volume: 313822784.297933,
              TradesCount: 245235,
              Time: '2024-06-24T06:29:05Z'
            }
          ]
        }
      }
    }
  },
  getters: {
    getFeedTotalVolume: (state) => (address, chainId, feed) => {
      return state.oracles[
        address + '-' + chainId + '-' + feed
      ]?.GetFeedAggregation?.reduce((acc, cur) => acc + cur.Volume, 0)
    },
    getFeedTotalLiquidity: (state) => (address, chainId, feed) => {
      return state.oracles[
        address + '-' + chainId + '-' + feed
      ]?.GetFeedAggregation?.reduce((acc, cur) => acc + cur.PoolLiquidityUSD, 0)
    },
    getFeedTotalTrades: (state) => (address, chainId, feed) => {
      return state.oracles[
        address + '-' + chainId + '-' + feed
      ]?.GetFeedAggregation?.reduce((acc, cur) => acc + cur.TradesCount, 0)
    }
  },
  actions: {
    closeModal() {
      this.showModal = false
    }
  }
})

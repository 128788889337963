<template>
  <div class="buttons-row">
    <button
      type="button"
      class="btn red"
      @click.prevent="cancelPlan"
      v-html="cancelTexts.modal_button"
    ></button>
    <button
      type="button"
      class="opacity-60"
      @click.prevent="modalStore.closeModal"
      v-html="cancelTexts.modal_stay"
    ></button>
  </div>
</template>

<script setup>
const modalStore = useModalStore()

const textsStore = useTextsStore()
const cancelTexts = computed(() => textsStore.account.plan.box_cancel)

const cancelPlan = () => {
  //TODO: Kamil - add functionality
  console.log('cancelPlan')
}
</script>

<style lang="scss" scoped></style>

export default function (value) {
  if (window.clipboardData && window.clipboardData.setData) {
    return window.clipboardData.setData('Text', text)
  } else {
    const textarea = document.createElement('textarea')
    textarea.textContent = value
    textarea.style.position = 'fixed'
    document.body.appendChild(textarea)
    textarea.select()
    try {
      return document.execCommand('copy')
    } catch (ex) {
      return false
    } finally {
      document.body.removeChild(textarea)
    }
  }

  return false
}

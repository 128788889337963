<template>
  <label
    class="field-wrapper"
    :class="{
      'has-error': props.error,
      'has-value': model !== '',
      'is-loading': props.isLoading,
      focus: isFocused
    }"
  >
    <DeployerUiFormLabel :label="props.label" :tooltip="props.tooltip" />
    <div v-if="props.topError.length" class="top-error">
      <p class="validation-message invalid">
        <span class="icon" v-html="ErrorSvg" />
        <span>{{ props.topError }}</span>
      </p>
    </div>
    <div class="field">
      <span v-if="props.svgIcon" class="icon-wrapper" v-html="props.svgIcon" />
      <span v-if="props.showInitialIcon" class="initial-icon">{{
        model[0]
      }}</span>
      <div class="input-wrapper">
        <input
          ref="refInput"
          v-model="model"
          :type="props.inputType"
          class="input"
          :class="{ fullwidth }"
          @focus="isFocused = true"
          @blur="isFocused = false"
        />
        <div
          v-if="props.placeholder"
          class="placeholder"
          v-html="placeholder"
        />
      </div>
      <div v-if="props.selectValues.length > 0" class="select">
        <button
          v-for="option in props.selectValues"
          :key="`btn-${option}`"
          type="button"
          class="select-button"
          :class="{ selected: option === props.selectedValue }"
          :disabled="props.selectValues.length === 1"
          @click.prevent="emits('selectValue', option)"
        >
          {{ option }}
        </button>
      </div>
      <div v-if="props.showButton" class="select">
        <button
          type="button"
          class="save-button"
          @click.prevent="emits('buttonClick')"
        >
          {{ props.buttonLabel }}
        </button>
      </div>
      <div v-if="props.error" class="error">
        <span v-html="ErrorSvg" /> {{ error }}
      </div>
    </div>
  </label>
</template>

<script setup>
import ErrorSvg from '~/layers/base/assets/icons/x.svg?raw'
const props = defineProps({
  label: {
    type: String,
    default: ''
  },
  inputType: {
    type: String,
    default: 'text'
  },
  placeholder: {
    type: String,
    default: ''
  },
  placeholderIcon: {
    type: String,
    default: ''
  },
  tooltip: {
    type: String,
    default: ''
  },
  selectValues: {
    type: Array,
    default: () => []
  },
  selectedValue: {
    type: String,
    default: ''
  },
  error: {
    type: String,
    default: ''
  },
  topError: {
    type: String,
    default: ''
  },
  svgIcon: {
    type: String,
    default: ''
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  fullwidth: {
    type: Boolean,
    default: false
  },
  showInitialIcon: {
    type: Boolean,
    default: false
  },
  buttonLabel: {
    type: String,
    default: ''
  },
  showButton: {
    type: Boolean,
    default: false
  }
})
const model = defineModel()
const refInput = ref(null)

const emits = defineEmits(['selectValue', 'buttonClick'])
const isFocused = ref(false)
</script>

<style lang="scss" scoped>
.field-wrapper {
  display: block;
  letter-spacing: 0.15px;
}

.input-wrapper {
  flex: 1 1 0;
  position: relative;
  letter-spacing: 0.15px;
}

.input {
  padding-inline: 0px;
  padding-top: 4px;
  font-size: 1.5rem;
  border: 0;
  width: calc(100% - 20px);
  height: 100%;
  border-radius: 10px;
  letter-spacing: 0.15px;
  background: transparent;
  color: var(--Text, #000);

  // remove number interface
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    outline: none;
  }
}

.input.fullwidth {
  width: 100%;
}

.field {
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
  border: 1px solid var(--field-stroke, rgba(0, 0, 0, 0.2));
  background: var(--field-bg, #fff);
  height: 52px;
  width: 100%;
  padding-left: 20px;
  transition: border-color 0.2s;
  &:hover {
    border: 1px solid var(--field-stroke-hover, rgba(255, 255, 255, 0.4));
  }

  .focus & {
    border: 1px solid var(--CTA-50, rgba(61, 110, 246, 0.5));
  }

  & {
    padding-right: 20px;
  }

  .has-error & {
    border-color: #d83737;
  }
}

.placeholder {
  color: var(--Text, #000);
  opacity: 0.5;
  font-weight: 500;
  font-size: 1.5rem;
  position: absolute;
  left: 0;
  top: 4px;
  pointer-events: none;
  transition:
    opacity 0.4s,
    transform 0.4s;

  .has-value & {
    opacity: 0;
    transform: translateX(15px);
  }
}

.select {
  position: absolute;
  top: 9px;
  bottom: 9px;
  right: 10px;
  border-radius: 10px;
  background: var(--Banner-5, rgba(0, 0, 0, 0.05));
  padding: 4px;
  display: flex;
  gap: 8px;
}

.select-button,
.save-button {
  line-height: 100%;
  font-size: 1.2rem;
  color: var(--Text-50, #000000);
  font-weight: 500;
  letter-spacing: 0.12px;
  border-radius: 8px;
  height: 24px;
  width: 34px;
  text-align: center;
  transition:
    background 0.2s,
    box-shadow 0.2s;

  &:hover:not([disabled]),
  &.selected {
    background: #fff;
    color: #000;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
  }

  &[disabled]:hover {
    cursor: default;
  }
}

.save-button {
  text-transform: uppercase;
  letter-spacing: 0.04em;
  padding-inline: 20px;
  width: auto;
  color: var(--Text);
}

.error {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;

  color: #d83737;
  margin-top: 2px;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  gap: 10px;
  span {
    position: relative;
    top: -1px;
  }
}

.icon-wrapper {
  width: 17px;
  padding-top: 1px;
  opacity: 0.6;
  :deep(svg) {
    width: 17px;
    height: 17px;
    display: block;
    transition: opacity 0.2s;
    * {
      stroke: var(--Text, #000);
    }
  }

  .is-loading & {
    background: url(/images/loading.gif) no-repeat bottom center;
    background-size: 17px 17px;

    :deep(svg) {
      opacity: 0;
    }
  }
}

.initial-icon {
  width: 20px;
  height: 20px;
  background: var(--CTA);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: var(--CTA-text);
  font-size: 1.5rem;
  font-weight: 500;
  padding-top: 2px;
  margin-left: -5px;
}

.top-error p {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  color: #368f36;
}

.top-error .icon {
  margin-top: -3px;
  margin-right: 5px;
}

.top-error p.invalid {
  color: #e71313;
}

.top-error i {
  margin-right: 10px;
}
</style>

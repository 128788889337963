<template>
  <div class="mobile-bar">
    <NuxtLink to="/deploy/" class="mb-btn">
      <div class="mb-btn__icon" v-html="CreateSvg"></div>
      Create
    </NuxtLink>
    <button
      typ="button"
      class="mb-btn"
      @click.prevent="showOracles('ecosystem')"
      v-if="showEcosystem"
      :class="{
        'router-link-active':
          route.path === '/' &&
          newOraclesStore.selectedOraclesTab === 'ecosystem'
      }"
    >
      <div class="mb-btn__icon" v-html="EcosystemSvg"></div>
      Ecosystem
    </button>
    <button
      type="button"
      class="mb-btn my-oracles"
      :class="{
        'router-link-active':
          route.path === '/' &&
          (newOraclesStore.selectedOraclesTab === 'user' ||
            newOraclesStore.selectedOraclesTab === 'deleted')
      }"
      @click.prevent="showOracles('user')"
    >
      <div class="mb-btn__icon" v-html="MyOraclesSvg"></div>
      My oracles
      <div class="count">{{ activeOracles?.length || 0 }}</div>
    </button>
    <NuxtLink to="/account/" class="mb-btn">
      <div class="mb-btn__icon" v-html="AccountSvg"></div>
      Account
    </NuxtLink>
  </div>
</template>

<script setup>
import CreateSvg from '~/layers/base/assets/icons/mobile-create.svg?raw'
import EcosystemSvg from '~/layers/base/assets/icons/mobile-ecosystem.svg?raw'
import MyOraclesSvg from '~/layers/base/assets/icons/mobile-my-oracles.svg?raw'
import AccountSvg from '~/layers/base/assets/icons/mobile-account.svg?raw'

const newOraclesStore = useNewOraclesStore()

const route = useRoute()

const showEcosystem = computed(() => {
  //TODO: add logic when ecosystem oracles fetching will be done
  return false
})

const showOracles = async (tab) => {
  newOraclesStore.selectedOraclesTab = tab
  await navigateTo('/')
}

const activeOracles = computed(() => {
  return newOraclesStore.oracles?.filter((oracle) => !oracle.Deleted)
})
</script>

<style lang="scss" scoped>
.mobile-bar {
  @media (min-width: 1024px) {
    display: none;
  }
  @media (max-width: 1023px) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 57px;
    padding-top: 5px;
    z-index: 99;
    border-top: 1px solid var(--Block-stroke, rgba(0, 0, 0, 0.1));
    background: var(--Block-bg, #fff);
    backdrop-filter: blur(20px);
    padding-inline: 20px;
    display: flex;
    justify-content: space-between;
    gap: 5px;
  }
}

.mb-btn {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-bottom: 2px solid transparent;
  padding-inline: 5px;
  font-size: 1.1rem;
  font-weight: 500;
  flex: 1 1 0;
  max-width: 65px;
  white-space: nowrap;
  line-height: 100%;
  color: var(--Text-50, #000);

  &__icon {
    :deep(path) {
      stroke: var(--Text-50, #000);
    }
  }

  &.router-link-active {
    color: var(--Text, #000);
    border-color: var(--Text, #000);

    :deep(path) {
      stroke: var(--CTA-text, #fff);
      fill: var(--Text, #000);
    }
  }

  &.my-oracles.router-link-active {
    :deep(path) {
      stroke: var(--Text, #000);
    }
  }

  .count {
    position: absolute;
    right: 0;
    top: 0;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--CTA-text, #fff);
    font-size: 1.1rem;
    border-radius: 21px;
    border: 1px solid var(--bubble-10, rgba(0, 0, 0, 0.1));
    background: var(--CTA, #3d6ef6);
    opacity: 1;
  }
}
</style>

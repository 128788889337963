<template>
  <div class="tooltip-wrapper" v-if="props.infoText">
    <div class="tooltip" v-html="props.infoText"></div>
    <div class="tooltip-opener" v-html="QuestionMarkSvg"></div>
  </div>
</template>

<script setup>
import QuestionMarkSvg from '~/layers/base/assets/icons/question-mark.svg?raw'
const props = defineProps({
  infoText: String
})
</script>

<style lang="scss" scoped>
.tooltip-wrapper {
  display: inline-block;
  position: relative;
  margin-left: 5px;
  color: var(--Text, #000);
  z-index: 99;
  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: -20px;
    right: -20px;
    height: 40px;
    pointer-events: none;
  }

  &:hover {
    .tooltip {
      opacity: 1;
      transform: translateY(0);
      pointer-events: auto;
    }

    &::before {
      pointer-events: auto;
    }
  }
}

.tooltip-opener {
  width: 11px;
  height: 11px;

  :deep(svg) {
    width: 11px;
    height: 11px;
    display: block;

    path {
      stroke: var(--Text, #000);
    }
  }
}

.tooltip {
  background: #000;
  border-radius: 10px;
  font-size: 1.2rem;
  padding: 10px 25px;
  position: absolute;
  bottom: calc(100% + 17px);
  left: calc(50% - 120px);
  width: 240px;
  text-align: center;
  pointer-events: none;
  opacity: 0;
  transform: translateY(5px);
  transition: opacity 0.2s, transform 0.2s;
  color: #fff;
  font-weight: 400;
  line-height: 120%;
  z-index: 99;

  :deep(a) {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: 112px;
    border-top: 10px solid #000;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
  }

  &.show {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
  }
}
</style>

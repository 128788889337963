export default function (token) {
  if (!token) {
    return ''
  }
  return (
    'https://stage-dia-cms.kliks.pl/logos/tokens/' +
    token.replace(/\s/g, '-') +
    '.png'
  )
}

<template>
  <div class="team-name">
    <div class="initial">
      {{ accountStore.teamName[0] }}
    </div>
    {{ accountStore.teamName }}
  </div>
</template>

<script setup>
const accountStore = useAccountStore()
</script>

<style lang="scss" scoped>
.team-name {
  background: var(--Block-bg);
  border: 1px solid var(--field-stroke);
  border-radius: 10px;
  line-height: 100%;
  padding: 10px;
  font-weight: 500;
  color: var(--Text-70);
  display: flex;
  align-items: center;
  gap: 10px;
}

.initial {
  width: 20px;
  height: 20px;
  background: var(--CTA);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: var(--CTA-text);
  font-size: 1.5rem;
  font-weight: 500;
  padding-top: 2px;
}
</style>

<template>
  <div class="dsr data-row data-pair-row">
    <div class="dsr__col source">
      <div class="pair-assets-icons">
        <img
          :src="getTokenIconUrl(props.pair.Quotetokensymbol)"
          width="25"
          height="25"
          class="icon"
        /><img
          :src="getTokenIconUrl(props.pair.Basetokensymbol)"
          width="25"
          height="25"
          class="icon pair-second-icon"
        />
      </div>
      {{ props.pair.Quotetokensymbol }}/{{ props.pair.Basetokensymbol }}
    </div>
    <div class="dsr__col text-right">
      $ {{ formatPrice(props.pair.LastPrice) }}
    </div>
    <div class="dsr__col text-right pr5">
      {{ liquidity }}
    </div>
    <div class="dsr__col text-right pr5">
      $ {{ formatPrice(props.pair.Volume, 0) }}
    </div>
    <div class="dsr__col text-right pr5">
      {{ props.pair.TradesCount.toLocaleString() }}
    </div>
    <div class="dsr__col text-right pr10"></div>
  </div>
</template>

<script setup>
const oraclesStore = useOraclesStore()
const props = defineProps({
  pair: Object
})

const exchangeIcon = computed(() => {
  // return oracleStore.getSourceIconUrl(props.pair.Exchange)
})
const opened = ref(false)

const liquidity = computed(() => {
  if (props.pair.PoolLiquidityUSD === 0) {
    return '-'
  }
  return `$ ${formatPrice(props.pair.PoolLiquidityUSD)}`
})
</script>

<style lang="scss" scoped>
.pair-assets-icons {
  position: relative;
  display: flex;

  .pair-second-icon {
    margin-left: -8px;
    position: relative;
    z-index: 2;
  }
}
</style>

<template>
  <div>
    <div class="inner" v-show="!inProgress">
      <DeployerModalsSubWalletSelection
        class="step-1"
        v-if="step === 1"
        @select-wallet="walletSelect"
      />

      <DeployerModalsSubTopUpValue
        v-else-if="step === 2 && type === 'connected'"
        @top-up-progress="inProgress = true"
        @transferred="transferred"
      />
      <DeployerModalsSubTransferred
        v-else-if="step === 3 && type === 'connected'"
      />

      <DeployerModalsSubDifferentWallet
        v-else-if="step === 2 && type === 'different'"
        @transferred="transferredDifferentWallet"
      />

      <DeployerModalsSubExecuted
        v-else-if="step === 3 && type === 'different'"
      />

      <DeployerModalsSubNotice
        :notice="textsStore.list.top_up?.notice"
        v-if="step === 2"
      />
    </div>
  </div>
</template>

<script setup>
const textsStore = useTextsStore()
const modalStore = useModalStore()

const step = ref(1)
const type = ref('')
const inProgress = ref(false)

//TODO: Kamil - add top-up functionality

const walletSelect = (walletType) => {
  step.value = 2
  type.value = walletType
}

const transferredDifferentWallet = () => {
  step.value = 3
}

const transferred = () => {
  step.value = 3
  inProgress.value = false
  modalStore.title = textsStore.list.top_up.title
  modalStore.text = textsStore.list.top_up.text
  modalStore.isLoading = false
  modalStore.canClose = true
}

watch(
  () => inProgress.value,
  (value) => {
    if (value) {
      modalStore.title = textsStore.list.top_up_progress.title
      modalStore.text = textsStore.list.top_up_progress.text
      modalStore.isLoading = true
      modalStore.canClose = false
    }
  }
)
</script>

<style lang="scss" scoped></style>

<template>
  <div class="label-wrapper">
    <span v-html="props.label" class="label-text"></span>
    <DeployerUiTooltip :info-text="props.tooltip" v-if="props.tooltip !== ''" />
  </div>
</template>

<script setup>
const props = defineProps({
  label: String,
  tooltip: {
    type: String,
    default: ''
  }
})
</script>

<style lang="scss" scoped>
.label-wrapper {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.label-text {
  opacity: 0.6;
  font-size: 1.5rem;
  font-weight: 500;

  :deep(em) {
    opacity: 0.5;
    font-style: normal;
  }
}
</style>

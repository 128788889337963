<template>
  <div class="update-item">
    <div class="before" v-html="UpdateSvg"></div>
    <div class="info">
      <strong>Oracle update</strong>&nbsp;
      <span v-if="!props.readMode"
        >on tx hash
        <a
          :href="`${chainData?.blockExplorer}${props.item.TransactionHash}`"
          target="_blank"
          class="tx-link"
          >{{ shortenAddress(props.item.TransactionHash) }}</a
        ></span
      >
      <span class="date">{{ date }}</span>
    </div>
    <div class="box" :class="{ opened }">
      <div class="row" @click.prevent="opened = !opened">
        <div class="asset">
          <div class="asset-icons">
            <img
              :src="getTokenIconUrl(symbol[0])"
              width="25"
              height="25"
              class="icon"
            />
            <img
              :src="getTokenIconUrl(symbol[1])"
              width="25"
              height="25"
              class="icon-2"
            />
          </div>
          {{ props.item.AssetKey }}
        </div>
        <div class="right">
          <div class="price">${{ assetPrice }}</div>
          <button
            type="button"
            class="toggle"
            v-html="ChevronUpSvg"
            v-if="!props.readMode"
          ></button>
        </div>
      </div>
      <div class="details" v-if="!props.readMode">
        <div class="info-row">
          <div class="nfo-row__label">Transaction cost:</div>
          <div class="info-row__value">
            {{ transactionCost
            }}<!-- {{ chainData?.currency }} -->
          </div>
        </div>
        <div class="info-row">
          <div class="nfo-row__label">Gas price:</div>
          <div class="info-row__value">
            {{ gasCost }}
            <!--{{ chainData?.currency }} -->
          </div>
        </div>
        <div class="info-row">
          <div class="nfo-row__label">Gas used:</div>
          <div class="info-row__value">
            {{ gasUsed }}
          </div>
        </div>
        <div class="info-row">
          <div class="nfo-row__label">Update block:</div>
          <div class="info-row__value">
            {{ props.item.UpdateBlock }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ChevronUpSvg from '~/assets/icons/chevron-up.svg?raw'
import UpdateSvg from '~/assets/icons/update.svg?raw'

const props = defineProps({
  item: Object,
  readMode: {
    type: Boolean,
    default: false
  }
})

// const oracleStore = useOracleStore()

const chainData = computed(() => {
  // return oracleStore.chainsData.find(
  //   (item) => parseInt(item.chainId) === parseInt(props.item.ChainID)
  // )
})

const opened = ref(false)

const date = computed(() => {
  let _date = useDateFormat(props.item.UpdateTime, 'YYYY-MM-DD HH:mm:ss')

  return _date.value
})

const assetPrice = computed(() => {
  return parseFloat(props.item.AssetPrice / 100000000).toLocaleString('en-Us', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
})

const transactionCost = computed(() => {
  return parseFloat(
    props.item.TransactionCost / 1000000000000000000
  ).toLocaleString('en-Us', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 20
  })
})

const gasCost = computed(() => {
  return parseFloat(props.item.GasCost / 1000000000000000000).toLocaleString(
    'en-Us',
    {
      minimumFractionDigits: 0,
      maximumFractionDigits: 20
    }
  )
})

const gasUsed = computed(() => {
  return parseFloat(props.item.GasUsed).toLocaleString('en-Us')
})

const symbol = computed(() => {
  return [...props.item.AssetKey.split('/')]
})
</script>

<style lang="scss" scoped>
.update-item {
  padding-right: 100px;
  padding-left: 35px;
  margin-bottom: 30px;
  position: relative;

  .before {
    content: '';
    position: absolute;

    left: 0;
    top: -1px;
    width: 20px;
    height: 20px;
    background: url(/images/oracle/update-item.svg) no-repeat center center;
  }

  &::after {
    content: '';
    position: absolute;
    left: 10px;
    top: 30px;
    bottom: -10px;
    width: 1px;
    background: var(--banner-10, rgba(0, 0, 0, 0.1));
  }
}

.info {
  margin-bottom: 10px;
}

.tx-link {
  text-decoration: underline;
  font-weight: 700;

  &:hover {
    text-decoration: none;
  }
}

.date {
  display: inline-block;
  margin-left: 8px;
  opacity: 0.75;
}

.info-row {
  display: flex;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.asset {
  display: flex;
  align-items: center;
  font-weight: 700;
  gap: 10px;

  .asset-icons {
    display: flex;
    position: relative;
    top: -1px;
  }

  .icon-2 {
    margin-left: -5px;
  }
}
.price {
  border-radius: 10px;
  background: var(--CTA, #3d6ef6);
  color: var(--CTA-text, #fff);
  padding: 8px 10px 6px;
  font-weight: 700;
}

.box {
  padding: 20px;
  border-radius: 10px;
  border: 1px solid var(--banner-10, rgba(0, 0, 0, 0.1));
  background: var(--Banner-5, rgba(0, 0, 0, 0.05));

  .info-row {
    font-size: 1.6rem !important;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.details {
  max-height: 0;
  transition: max-height 0.2s, margin-top 0.2s, padding-top 0.2s;
  overflow: hidden;

  .opened & {
    max-height: 200px;
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid var(--Banner-5, rgba(0, 0, 0, 0.05));
  }
}

.value {
  font-weight: 500;
}

.right {
  display: flex;
  gap: 10px;
}

.toggle {
  width: 32px;
  height: 32px;
  border-radius: 10px;
  background: var(--banner-10, rgba(0, 0, 0, 0.1));
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  :deep(svg) {
    width: 10px;
    transition: transform 0.3s;
    transform: rotate(180deg);
    path {
      stroke: var(--Text, #000);
    }

    .opened & {
      transform: rotate(0deg);
    }
  }
}
</style>

<template>
  <div class="data-row-wrapper" :class="{ opened }">
    <button
      type="button"
      class="dsr data-row"
      :class="{ opened }"
      @click.prevent="opened = !opened"
    >
      <div class="dsr__col source">
        <img :src="getSourceIconUrl(sourceName)" width="25" height="25" />
        {{ sourceName }}
      </div>
      <div class="dsr__col text-right">-</div>
      <div class="dsr__col text-right">
        {{ totalLiquidity }}
      </div>
      <div class="dsr__col text-right">$ {{ formatPrice(totalVolume, 0) }}</div>
      <div class="dsr__col text-right">{{ totalTrades.toLocaleString() }}</div>
      <div class="dsr__col text-right pr10">
        <div class="pairs-btn">
          {{ props.group.length }}
          <div v-html="ChevronSvg" class="chevron"></div>
        </div>
      </div>
    </button>
    <div class="sub-rows-wrapper">
      <div class="sub-rows-wrapper__inner">
        <DeployerFeedDataSourceRowPair
          v-for="(item, index) in props.group"
          :key="`sp-${item.Exchange}-${item.Basetokenaddress}`"
          :pair="item"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import ChevronSvg from '~/assets/icons/chevron-up.svg?raw'
const oraclesStore = useOraclesStore()
const props = defineProps({
  group: Object
})

const sourceName = computed(() => props.group[0].Exchange)

const exchangeIcon = computed(() => {
  // return oracleStore.getSourceIconUrl(sourceName.value)
})

const totalLiquidity = computed(() => {
  const total = props.group.reduce((acc, source) => {
    return acc + source.PoolLiquidityUSD
  }, 0)
  if (total === 0) {
    return '-'
  }
  return `$ ${formatPrice(total)}`
})

const totalVolume = computed(() => {
  return props.group.reduce((acc, source) => {
    return acc + source.Volume
  }, 0)
})

const totalTrades = computed(() => {
  return props.group.reduce((acc, source) => {
    return acc + source.TradesCount
  }, 0)
})

const opened = ref(false)
</script>

<style lang="scss" scoped>
.sub-rows-wrapper {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.3s ease-in-out;
  position: relative;
  top: -5px;
  &__inner {
    overflow: hidden;
  }
  .opened & {
    grid-template-rows: 1fr;
  }
}
</style>

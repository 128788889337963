<template>
  <div
    role="radio"
    :aria-checked="props.isChecked"
    class="radio"
    :class="{ checked: props.isChecked }"
  ></div>
</template>

<script setup>
const props = defineProps({
  isChecked: Boolean
})
</script>

<style lang="scss" scoped>
.radio {
  width: 16px;
  height: 16px;
  background: var(--Banner-5, rgba(0, 0, 0, 0.05));
  border: 1px solid var(--bubble-10, rgba(0, 0, 0, 0.1));
  border-radius: 50%;
  position: relative;
  transition:
    background-color 0.2s,
    border-color 0.2s;

  &.checked {
    background: var(--CTA);
    border-color: var(--CTA);

    &::after {
      content: '';
      display: block;
      position: absolute;
      left: calc(50% - 3px);
      top: calc(50% - 3px);
      width: 6px;
      height: 6px;
      background: #fff;
      border-radius: 50%;
    }
  }
}
</style>

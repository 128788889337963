import { defineStore } from 'pinia'

export const ACCESS_LEVELS = {
  READ: 'read',
  READ_WRITE: 'read_write'
}

export const useAccountStore = defineStore('accountStore', {
  state: () => ({
    username: 'John Doe',
    teamName: 'Team Name',
    team: [],
    account: null,
    viewDashboardSignedData: null,
    payment: null
  }),
  getters: {
    getTeamInitials() {
      return this.team.map((member) => member.public_key[0])
    },

    getAccountLimitErrors() {
      if (!this.account) return null

      const errors = []

      if (this.account.number_of_data_feeds >= this.account.plan.total_feeds) {
        errors.push('You reached your limit of price feeds.')
      }

      if (this.account.deployed_oracles >= this.account.plan.total_oracles) {
        errors.push('You reached your limit of oracle deploys.')
      }

      return errors
    },

    getAccountNumberOfFeeds() {
      if (!this.account) {
        return {
          deployed: 0,
          maximum: 0
        }
      }

      return {
        deployed: this.account.number_of_data_feeds,
        maximum: this.account.plan.total_feeds
      }
    }
  },

  actions: {
    async viewAccount() {
      const config = useRuntimeConfig()
      const newWalletStore = useNewWalletStore()
      const chainId = newWalletStore.account.chainId
      const loggedInAddress = newWalletStore.account.address

      const signedData = await newWalletStore.signData(
        chainId,
        'Verify its your address to View Account',
        loggedInAddress
      )

      const formdata = new FormData()
      formdata.append('chainId', chainId)
      formdata.append('creator', loggedInAddress)

      const requestOptions = {
        method: 'POST',
        body: formdata,
        headers: {
          Authorization: `Bearer ${signedData}`
        }
      }

      try {
        const res = await fetch(
          `${config.public.oracleApiBaseURL}/oraclebuilder-prod/account/view`,
          requestOptions
        )
        const accountResponse = await res.json()

        if (accountResponse.error) return null

        this.team = accountResponse?.public_keys
        this.account = accountResponse

        return accountResponse
      } catch (err) {
        console.error(err)
        throw new Error('Failed to create and register oracle')
      }
    },

    async addWalletToAccount(
      newWalletAddress,
      username,
      accessLevel = ACCESS_LEVELS.READ
    ) {
      const config = useRuntimeConfig()
      const newWalletStore = useNewWalletStore()
      const chainId = newWalletStore.account.chainId
      const loggedInAddress = newWalletStore.account.address

      const signedData = await newWalletStore.signData(
        chainId,
        'Verify its your address to Add Wallet',
        loggedInAddress
      )

      const formdata = new FormData()

      formdata.append('chainId', chainId)
      formdata.append('wallet_public_keys[]', newWalletAddress)
      formdata.append('creator', loggedInAddress)
      formdata.append('username', username)
      formdata.append('access_level', accessLevel)

      const requestOptions = {
        method: 'POST',
        body: formdata,
        headers: {
          Authorization: `Bearer ${signedData}`
        }
      }

      try {
        const res = await fetch(
          `${config.public.oracleApiBaseURL}/oraclebuilder-prod/account/addWallet`,
          requestOptions
        )
        const response = await res.json()

        if (!response.error)
          this.team.push({
            public_key: newWalletAddress,
            username,
            access_level: accessLevel
          })

        return true
      } catch (err) {
        console.error(err)
        throw new Error('Failed to create and register oracle')
      }
    },

    async updateAccess(publicKey, accessLevel) {
      if (Object.values(ACCESS_LEVELS).indexOf(accessLevel) === -1) {
        throw new Error('Invalid access level')
      }
      const config = useRuntimeConfig()
      const newWalletStore = useNewWalletStore()
      const chainId = newWalletStore.account.chainId
      const loggedInAddress = newWalletStore.account.address

      const signedData = await newWalletStore.signData(
        chainId,
        'Verify its your address to Update Access',
        loggedInAddress
      )

      const formdata = new FormData()

      formdata.append('chainId', chainId)
      formdata.append('wallet_public_key', publicKey)
      formdata.append('access_level', accessLevel)
      formdata.append('creator', loggedInAddress)

      const requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow',
        headers: {
          Authorization: `Bearer ${signedData}`
        }
      }

      try {
        const res = await fetch(
          `${config.public.oracleApiBaseURL}/oraclebuilder-prod/account/updateAccess`,
          requestOptions
        )
        const response = await res.json()

        if (!response.error) {
          const index = this.team.findIndex(
            (member) => member.public_key === publicKey
          )
          this.team[index].access_level = accessLevel
        }

        return response.publicKey
      } catch (err) {
        console.error(err)
        throw new Error('Failed to create and register oracle')
      }
    },

    async createAccount() {
      const config = useRuntimeConfig()
      const newWalletStore = useNewWalletStore()
      const chainId = newWalletStore.account.chainId
      const loggedInAddress = newWalletStore.account.address

      const signedData = await newWalletStore.signData(
        chainId,
        config.public.createNewCustomerMessage,
        loggedInAddress
      )

      const formdata = new FormData()
      formdata.append('email', 'some@rmail.com')

      formdata.append('chainId', chainId)
      const walletPublicKeys = [loggedInAddress]

      walletPublicKeys.forEach((key, index) => {
        formdata.append('wallet_public_keys[]', key)
      })

      formdata.append('creator', loggedInAddress)

      const requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow',
        headers: {
          Authorization: `Bearer ${signedData}`
        }
      }

      try {
        const res = await fetch(
          `${config.public.oracleApiBaseURL}/oraclebuilder-prod/createAccount`,
          requestOptions
        )
        const d = await res.json()
        return d.publicKey
      } catch (err) {
        console.error(err)
        throw new Error('Failed to create and register oracle')
      }
    },

    async removeWalletFromAccount(publicKey) {
      const config = useRuntimeConfig()
      const newWalletStore = useNewWalletStore()
      const chainId = newWalletStore.account.chainId
      const loggedInAddress = newWalletStore.account.address

      const signedData = await newWalletStore.signData(
        chainId,
        'Verify its your address to Remove Wallet',
        loggedInAddress
      )

      const formdata = new FormData()

      formdata.append('chainId', chainId)
      formdata.append('wallet_public_keys[]', publicKey)
      formdata.append('creator', loggedInAddress)

      const requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow',
        headers: {
          Authorization: `Bearer ${signedData}`
        }
      }

      try {
        const res = await fetch(
          `${config.public.oracleApiBaseURL}/oraclebuilder-prod/account/removeWallet`,
          requestOptions
        )
        const response = await res.json()

        if (!response.error) {
          const index = this.team.findIndex(
            (member) => member.public_key === publicKey
          )
          this.team.splice(index, 1)
        }

        return true
      } catch (err) {
        console.error(err)
        throw new Error('Failed to create and register oracle')
      }
    },

    async viewDashboard(newWallet = false) {
      const config = useRuntimeConfig()
      const newOraclesStore = useNewOraclesStore()
      const newWalletStore = useNewWalletStore()
      const modalStore = useModalStore()
      const chainId = newWalletStore.account.chainId
      const loggedInAddress = newWalletStore.account.address

      if (!this.viewDashboardSignedData || newWallet) {
        // wait 500ms to allow the wallet to be connected
        await new Promise((resolve) => setTimeout(resolve, 1000))

        const signedData = await newWalletStore.signData(
          chainId,
          'Verify its your address to List your oracles and View Account',
          loggedInAddress
        )

        if (signedData) this.viewDashboardSignedData = signedData
      }

      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: {
          Authorization: `Bearer ${this.viewDashboardSignedData}`
        }
      }

      try {
        const res = await fetch(
          `${config.public.oracleApiBaseURL}/oraclebuilder-prod/account/dashboard?creator=${loggedInAddress}&chainID=${chainId}`,
          requestOptions
        )

        const d = await res.json()

        if (d.account) {
          this.account = d.account

          if (d.account.pending_public_keys) {
            modalStore.showModal('join-team')
          } else if (!d.account.customer_id) {
            modalStore.showModal('create-team')
          }
        }

        if (d.oracles) newOraclesStore.oracles = d.oracles

        if (d.payment) {
          this.payment = d.payment

          if (d.payment?.AgreementSignedUp && !d.payment.TransferProcessed) {
            await new Promise((resolve) => setTimeout(resolve, 10000))
            await this.viewDashboard()
          }
        }

        return d
      } catch (err) {
        console.error(err)
        throw new Error('Failed to viewDashboard')
      }
    }
  }
})

<template>
  <form>
    <div class="team-name-wrapper">
      <DeployerUiFormInputText
        v-model="teamName"
        :label="textsStore.account.create_team_modal.input_label"
        :placeholder="textsStore.account.create_team_modal.input_placeholder"
        :error="validationError"
      />
    </div>

    <div class="mt30 buttons-row buttons-row--mobile-column">
      <button
        type="submit"
        class="btn cta"
        :disabled="loading || !teamName?.length"
        @click.prevent="createTeam"
      >
        {{
          loading
            ? '...loading'
            : textsStore.account.create_team_modal.create_team_button_label
        }}
      </button>
      <button
        v-if="accountStore.account?.pending_public_keys?.length > 0"
        type="button"
        class="btn transparent ml5"
        @click.prevent="joinExistingTeam"
      >
        {{ textsStore.account.create_team_modal.join_button_label }}
      </button>
    </div>
  </form>
</template>

<script setup>
const accountStore = useAccountStore()
const modalStore = useModalStore()
const textsStore = useTextsStore()

const teamName = ref('')
const validationError = ref('')
const loading = ref(false)

async function createTeam() {
  validationError.value = ''

  if (!teamName.value.length) {
    validationError.value = 'Team name is required'
    return
  }

  accountStore.teamName = teamName.value

  await accountStore.createAccount()
  await accountStore.viewDashboard()
  modalStore.showModal('select-plan')
}

function joinExistingTeam() {
  modalStore.showModal('join-team')
}
</script>

<style scoped lang="scss">
.team-name-wrapper {
  max-width: 329px;
}
</style>

import { defineStore } from 'pinia'

export const useTextsStore = defineStore('textsStore', {
  state: () => {
    return {
      list: {},
      account: {},
      deploy: {}
    }
  }
})

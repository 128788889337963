import { defineStore } from 'pinia'

export const useModalStore = defineStore('modalStore', {
  state: () => ({
    show: false,
    canClose: true,
    type: '',
    title: '',
    subtitle: '',
    text: '',
    oracle: {
      address: '',
      chainId: '',
      symbol: null
    },
    isLoading: false
  }),
  actions: {
    closeModal() {
      this.show = false
      this.type = ''
      this.canClose = true
      this.title = ''
      this.text = ''
      this.oracle = {
        address: '',
        chainId: '',
        symbol: null
      }
      this.isLoading = false
    },

    showModal(type, title = '', text = '', subtitle = '', canClose = true) {
      this.show = true
      this.type = type
      this.title = title
      this.subtitle = subtitle
      this.text = text
      this.canClose = canClose
    }
  }
})

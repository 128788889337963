import { useNewWalletStore } from '~/stores/newWallet'
import { reconnect, watchAccount } from '@wagmi/core'

export default defineNuxtPlugin(async () => {
  const newWalletStore = useNewWalletStore()
  const accountStore = useAccountStore()

  reconnect(newWalletStore.wagmiConfig)

  const unwatch = watchAccount(newWalletStore.wagmiConfig, {
    onChange(account) {
      if (
        newWalletStore.account?.address &&
        newWalletStore.account?.address !== account.address
      ) {
        newWalletStore.account = account
        newWalletStore.getConnectedWalletBalance()

        accountStore.viewDashboard(true)

        return
      }

      newWalletStore.account = account

      newWalletStore.getConnectedWalletBalance()
    }
  })
})

export default function (dateString) {
  if (!dateString) return { date: '', time: '' }
  const date = new Date(dateString)

  const localDate = new Date(date.getTime())

  const optionsDate = { month: 'short', day: 'numeric', year: 'numeric' }
  const optionsTime = {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZone: 'UTC'
  }

  const parsedDate = localDate.toLocaleDateString()
  const parsedTime = localDate.toLocaleTimeString()

  return { date: parsedDate, time: parsedTime }
}

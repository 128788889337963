<template>
  <div>
    <div class="teams">
      <button
        type="button"
        class="team"
        v-for="item in invitations"
        @click.prevent="selectedTeam = item"
      >
        <div class="radio-wrapper">
          <DeployerUiFormRadioButton :is-checked="item === selectedTeam" />
        </div>
        <div class="info">
          <div class="ir"><strong>Team:</strong> {{ item.username }}</div>
          <div class="ir">
            <strong>Your role:</strong> {{ item.access_level }}
          </div>
          <div class="ir">
            <strong>Invited by:</strong> {{ item.public_key }}
            <DeployerUiCopy :copy-value="item.public_key" />
          </div>
        </div>
      </button>
    </div>
    <div class="buttons-row buttons-row--mobile-column">
      <button
        type="button"
        class="btn cta"
        @click.prevent="joinTeam"
        :disabled="selectedTeam === null"
      >
        {{ textsStore.account.join_team_modal.join_button_label }}
      </button>
      <button
        type="button"
        class="btn transparent ml5"
        @click.prevent="createOwnTeam"
      >
        {{ textsStore.account.join_team_modal.create_team_button_label }}
      </button>
    </div>
  </div>
</template>

<script setup>
const accountStore = useAccountStore()
const modalStore = useModalStore()
const textsStore = useTextsStore()

const invitations = computed(() => accountStore.account?.pending_public_keys)
const selectedTeam = ref(null)

const joinTeam = () => {
  console.log('Join team', selectedTeam.value)
  modalStore.closeModal()
}

const createOwnTeam = () => {
  modalStore.showModal('create-team')
}
</script>

<style scoped lang="scss">
.teams {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 270px;
  overflow-y: auto;
  margin-bottom: 30px;
  margin-top: -20px;

  @media (max-width: 750px) {
    max-height: none;
  }

  &::-webkit-scrollbar {
    width: 3px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(29, 34, 68, 0.2);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #000d30;
  }
}

.team {
  display: flex;
  gap: 15px;
  padding: 20px;
  border: 1px solid var(--field-stroke);
  border-radius: 10px;

  @media (max-width: 750px) {
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
    // min-height: 140px;
  }
}

.info {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.ir {
  font-size: 1.5rem;
  opacity: 0.7;
  word-break: break-all;

  strong {
    font-weight: 500;
  }

  :deep(.copy-btn) {
    margin-left: 5px;
  }
}
</style>

<template>
  <div class="graph" :class="{ loading, dark: colorMode === 'dark' }">
    <div class="graph-title-row">
      <div>Price history</div>
      <div class="legend">
        <div class="legend-price">Oracle price update</div>
      </div>
      <div class="controls">
        <button
          type="button"
          class="btn grey small"
          :class="{ active: showDays === 1 }"
          @click.prevent="showDays = 1"
          v-if="!hide24h"
        >
          <div class="btn__inner">24h</div>
        </button>

        <button
          type="button"
          class="btn grey small"
          :class="{ active: showDays === 7 }"
          @click.prevent="showDays = 7"
        >
          <div class="btn__inner">7d</div>
        </button>
        <button
          type="button"
          class="btn grey small"
          :class="{ active: showDays === 30 }"
          @click.prevent="showDays = 30"
        >
          <div class="btn__inner">30d</div>
        </button>
      </div>
    </div>
    <apexchart
      height="327"
      width="100%"
      type="line"
      :options="options"
      :series="props.transactions"
      ref="refChart"
      v-if="props.showGraph"
    >
    </apexchart>
  </div>
</template>

<script setup>
const props = defineProps({
  transactions: {
    type: Array,
    required: true
  },
  showGraph: {
    type: Boolean,
    default: false
  }
})

const showDays = defineModel()

const refChart = ref(null)

const loading = computed(() => {
  return !props.showGraph
})

const hide24h = computed(() => {
  const now = Math.floor(new Date() / 1000)
  const showAfter = now - 24 * 3600

  const count = props.transactions[0].data.filter(
    (item) => Math.floor(new Date(item.UpdateTime) / 1000) > showAfter
  ).length

  if (count < 3) {
    return true
  }

  return false
})

const minMax = computed(() => {
  const min = Math.min(...props.transactions[0].data.map((t) => t[1]))
  const max = Math.max(...props.transactions[0].data.map((t) => t[1]))
  if (min < 10) {
    return {
      min: min - 0.3 * min,
      max: max + 0.3 * max
    }
  }
  return {}
})

const colorMode = useCookie('colorMode')

const lineColor = computed(() => {
  if (colorMode.value === 'dark') {
    return 'rgba(200, 253, 211, 1)'
  } else {
    return 'rgba(61, 110, 246, 1)'
  }
})
const textColor = computed(() => {
  if (colorMode.value === 'dark') {
    return 'rgba(255,255,255,0.6)'
  } else {
    return 'rgba(0,0,0,0.8)'
  }
})

const gridColor = computed(() => {
  if (colorMode.value === 'dark') {
    return 'rgba(255,255,255,0.05)'
  } else {
    return 'rgba(0,0,0,0.05)'
  }
})

const options = ref({
  chart: {
    type: 'area',
    toolbar: {
      show: false
    },
    zoom: {
      enabled: false
    },
    selection: {
      enabled: false
    }
  },

  colors: [lineColor.value],
  fill: {
    type: 'gradient',
    gradient: {
      type: 'vertical',
      shadeIntensity: 1,
      opacityFrom: 1,
      opacityTo: 0,
      stops: [0, 100],
      colorStops: [
        {
          offset: 0,
          color: lineColor.value,
          opacity: 0.3
        },
        {
          offset: 90,
          color: lineColor.value,
          opacity: 0
        }
      ]
    }
  },
  stroke: {
    width: [2]
  },
  title: {},

  grid: {
    show: true,
    borderColor: gridColor.value,
    xaxis: {
      lines: {
        show: true
      }
    },
    yaxis: {
      lines: {
        show: true
      }
    }
  },
  xaxis: {
    type: 'datetime',

    axisTicks: {
      show: false
    },
    axisBorder: {
      show: false
    },
    labels: {
      style: {
        colors: textColor.value,
        fontFamily: `'DiaType', sans-serif`,
        fontSize: '10px',
        fontWeight: 500
      },
      datetimeFormatter: {
        year: 'yyyy',
        month: "MMM 'yy",
        day: 'MMM dd, HH:mm',
        hour: 'MMM dd, HH:mm'
      },
      formatter: function (value, timestamp) {
        const date = new Date(timestamp)
        return [
          date.toLocaleDateString('en', { month: 'long', day: 'numeric' }),

          date.toLocaleTimeString('de', {
            hour: '2-digit',
            minute: '2-digit'
          })
        ]
      }
    }
  },
  markers: {
    size: 2,
    strokeWidth: 0,
    hover: {
      size: 5,
      strokeWidth: 2
    }
  },
  yaxis: {
    ...minMax.value,
    labels: {
      formatter: function (value) {
        if (value < 1) {
          return '$' + value.toFixed(2)
        }
        return '$' + value.toFixed(0)
      },
      style: {
        colors: textColor.value,
        fontFamily: `'DiaType', sans-serif`,
        fontSize: '12px',
        fontWeight: 500
      }
    }
  },

  tooltip: {
    theme: 'dark',
    custom: ({ series, seriesIndex, dataPointIndex, w }) => {
      return `<div class="gt">
  <div class="gt-top">${new Date(
    w.globals.seriesX[0][dataPointIndex]
  ).toLocaleString('en-GB')}</div>
  <div class="gt-row">
    <span class="gt-row__label">Price:</span>  $ ${
      series[0][dataPointIndex]
    } </div>
  </div>`
    },
    x: {
      format: 'MMM dd HH:mm:ss'
    }
  }
})
</script>

<style lang="scss" scoped>
.graph {
  border-radius: 10px;
  border: 1px solid var(--bubble-10, rgba(0, 0, 0, 0.1));
  background: var(--Data-sources, #fff);
  padding: 32px 15px 0 8px;
  height: 405px;

  &.loading {
    position: relative;

    :deep(.vue-apexcharts) {
      opacity: 0.1;
    }
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: url(/images/loading.gif) no-repeat center;
      z-index: 99;
      background-size: 30px;
    }
  }

  :deep(.apexcharts-xaxis-label) {
    &:nth-child(1) {
      opacity: 0;
    }

    tspan:nth-child(1) {
      opacity: 0.6;
    }
    tspan:nth-child(2) {
      opacity: 0.4;
    }
  }
  :deep(.apexcharts-yaxis-label) {
    opacity: 0.6;
  }
}

.graph-title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
  padding: 0 15px;
  margin-bottom: 5px;
  color: var(--Text-60, rgba(0, 0, 0, 0.6));
}

.legend {
  display: flex;
  gap: 15px;
  line-height: 100%;
}

.legend-price {
  display: flex;
  gap: 7px;
  align-items: center;

  &::before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-bottom: 2px;
  }
}

.legend-price {
  &::before {
    background: #3d6ef6;
  }

  .dark &::before {
    background: #c8fdd3;
  }
}

.controls {
  display: flex;
  gap: 10px;
}
</style>

<template>
  <div class="history-list">
    <div class="loading text-center" v-if="loading">
      <img src="/images/loading.gif" width="30" height="30" alt="Loading" />
    </div>
    <div>
      <DeployerModalsUpdateItem
        v-for="update in updates"
        :key="`ui-${update.TransactionHash}`"
        :item="update"
        :read-mode="modalStore.oracle.readMode"
      />
      <div class="load-more" v-if="currentPage < maxPage" ref="refLoadMore">
        <img src="/images/loading.gif" width="30" height="30" alt="Loading" />
      </div>
      <!-- <DeployerModalsUpdateCreated
        v-else-if="!loading && oracleStore.modalContent.createdDate"
      /> -->
    </div>
  </div>
</template>

<script setup>
const modalStore = useModalStore()

const loading = ref(true)
const updatesCount = ref(0)
const updates = ref([])
const currentPage = ref(1)
const maxPage = computed(() => Math.ceil(updatesCount.value / 20))
const refLoadMore = ref(null)
const isLoadMoreVisible = ref(false)
const intervalKeeper = ref(null)
const showCreated = ref(false)
const config = useRuntimeConfig()

const chainData = computed(() => {
  // return oracleStore.chainsData.find(
  //   (item) => item.name === oracleStore.modalContent.network
  // )
})

const { stop } = useIntersectionObserver(
  refLoadMore,
  ([{ isIntersecting }], observerElement) => {
    isLoadMoreVisible.value = isIntersecting
    if (isIntersecting) {
      currentPage.value++
      loadMoreUpdates()
    }
  }
)

const loadUrl = computed(() => {
  if (modalStore.oracle.symbol) {
    return `https://api.diadata.org/oraclebuilder-prod/stats?address=${modalStore.oracle.address}&chainID=${modalStore.oracle.chainId}&symbol=${modalStore.oracle.symbol}&page=${currentPage.value}`
  }
  return `https://api.diadata.org/oraclebuilder-prod/stats?address=${modalStore.oracle.address}&chainID=${modalStore.oracle.chainId}&page=${currentPage.value}`
})

const loadMoreUpdates = async () => {
  if (currentPage.value > maxPage.value) {
    return
  }
  const { data: historyMore, error } = await useLazyFetch(loadUrl.value)

  updates.value = [...updates.value, ...historyMore.value.Updates]
}

const { data: history, error } = useLazyFetch(loadUrl?.value)

const historyUpdate = async () => {
  const { data: historyUpdateLoad, error } = await useLazyFetch(loadUrl.value)
  const newUpdates = historyUpdateLoad.value.Updates.filter(
    (item) =>
      !updates.value.find(
        (update) => update.TransactionHash === item.TransactionHash
      )
  )

  updates.value = [...newUpdates, ...updates.value]
}

watch(history, (data) => {
  loading.value = false
  updatesCount.value = data.Count
  updates.value = data.Updates
})

onMounted(() => {
  historyUpdate()
  intervalKeeper.value = setInterval(() => {
    historyUpdate()
  }, 120000)
})

onUnmounted(() => {
  clearInterval(intervalKeeper.value)
})
</script>

<style lang="scss" scoped>
.history-wrapper {
  position: relative;
  &.loading {
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
}

.history-list {
  max-height: 500px;
  overflow-y: auto;
  scrollbar-gutter: stable;
  padding-top: 10px;

  &::-webkit-scrollbar {
    width: 3px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.2);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.2);
  }
}

.load-more {
  position: relative;
  left: -4px;
}
</style>

<template>
  <div class="data-sources">
    <div class="info">
      <div class="label">{{ texts[0].label }}</div>
      <div class="value">
        {{ props.sources.length }}
        <div class="sources">
          <div class="source" v-for="item in limitedPairs">
            <img
              :src="getSourceIconUrl(item[0].Exchange)"
              width="16"
              height="16"
            />
          </div>

          <div class="more" v-if="sourceLength > 5">
            {{ sourceLength - 5 }} +
          </div>
        </div>
      </div>
    </div>
    <div class="info">
      <div class="label">{{ texts[3].label }}</div>
      <div class="value">$ {{ formatPrice(totalVolume, 0) }}</div>
    </div>
    <div class="info">
      <div class="label">{{ texts[2].label }}</div>
      <div class="value">{{ totalLiquidity }}</div>
    </div>
    <div class="info">
      <div class="label">{{ texts[4].label }}</div>
      <div class="value">{{ formatPrice(totalTrades, 0) }}</div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  sources: Array,
  labels: Array
})
const textsStore = useTextsStore()
const texts = computed(() => {
  return textsStore.list.price_feeds.sources_labels
})

const groupedPairs = computed(() => {
  const pairs = props.sources.reduce((acc, source) => {
    if (!acc[source.Exchange]) {
      acc[source.Exchange] = []
    }
    acc[source.Exchange].push(source)
    return acc
  }, {})

  return pairs
})

const limitedPairs = computed(() => {
  const val = Object.entries(groupedPairs.value).slice(0, 5)

  return Object.fromEntries(val)
})

const sourceLength = computed(() => {
  return Object.keys(groupedPairs.value).length
})

const totalLiquidity = computed(() => {
  const total = props.sources.reduce((acc, source) => {
    return acc + source.PoolLiquidityUSD
  }, 0)
  if (total === 0) {
    return '-'
  }
  return `$ ${formatPrice(total)}`
})

const totalVolume = computed(() => {
  return props.sources.reduce((acc, source) => {
    return acc + source.Volume
  }, 0)
})

const totalTrades = computed(() => {
  return props.sources.reduce((acc, source) => {
    return acc + source.TradesCount
  }, 0)
})

const opened = ref(false)
</script>

<style lang="scss" scoped>
.data-sources {
  margin-top: 20px;
  border-radius: 10px;
  border: 1px solid var(--bubble-10, rgba(0, 0, 0, 0.1));
  background: var(--field-bg, #fff);
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 30px;

  .label {
    margin-bottom: 10px;
  }

  .sources {
    display: flex;
    position: relative;
    top: -2px;

    .source:not(:first-child) {
      margin-left: -5px;
    }

    .source:first-child {
      margin-left: 1px;
    }
  }

  .more {
    border-radius: 40px;
    border: 0.5px solid var(--field-stroke-hover, rgba(0, 0, 0, 0.4));
    background: var(--bubble-10, rgba(0, 0, 0, 0.1));
    backdrop-filter: blur(5px);
    margin-left: -5px;
    font-size: 1.2rem;
    padding: 0 5px;
    height: 16px;
    line-height: 120%;
  }
}
</style>

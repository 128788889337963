import { defineStore } from 'pinia'
import {
  arbitrumSepolia,
  mainnet,
  polygon,
  polygonMumbai,
  sepolia
} from 'viem/chains'
import { defineChain } from 'viem'
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi'
import { disconnect, getBalance, signTypedData } from '@wagmi/core'

// polygonMumbai.rpcUrls.public.http = [
//   'https://polygon-mumbai-bor.publicnode.com'
// ]
// polygonMumbai.rpcUrls.default.http = [
//   'https://polygon-mumbai-bor.publicnode.com'
// ]

const opCelestiaTestnet = defineChain({
  id: 123420111,
  name: 'OP Celestia Raspberry',
  network: 'opcelestia-testnet',
  nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://rpc.opcelestia-raspberry.gelato.digital']
    },
    public: {
      http: ['https://rpc.opcelestia-raspberry.gelato.digital']
    }
  },
  blockExplorers: {
    default: {
      name: 'Blockscout',
      url: 'https://opcelestia-raspberry.gelatoscout.com/'
    }
  }
})

const arbitrumBlueberryTestnet = defineChain({
  id: 88153591557,
  name: 'Arbitrum Blueberry',
  network: 'arb-blueberry',
  nativeCurrency: { name: 'CGT', symbol: 'CGT', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://rpc.arb-blueberry.gelato.digital']
    },
    public: {
      http: ['https://rpc.arb-blueberry.gelato.digital']
    }
  },
  blockExplorers: {
    default: {
      name: 'Blockscout',
      url: 'https://arb-blueberry.gelatoscout.com/'
    }
  }
})

const polygonBlackberryTestnet = defineChain({
  id: 94204209,
  name: 'Polygon Blackberry',
  network: 'polygon-blackberry',
  nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
  rpcUrls: {
    default: {
      http: ['https://rpc.polygon-blackberry.gelato.digital']
    },
    public: {
      http: ['https://rpc.polygon-blackberry.gelato.digital']
    }
  },
  blockExplorers: {
    default: {
      name: '',
      url: 'https://polygon-blackberry.gelatoscout.com/'
    }
  }
})

export const useNewWalletStore = defineStore('newWalletStore', () => {
  const config = useRuntimeConfig()
  const whitelabelStore = useWhitelabelStore()
  const accountStore = useAccountStore()

  const metadata = {
    name: 'Dia Data',
    description: 'Oracle deployer',
    url: whitelabelStore.host,
    icons: ['https://avatars.githubusercontent.com/u/37784886']
  }

  console.log(whitelabelStore.config.network_mainnet_chain_id)

  const whitelabel1 = defineChain({
    id: Number(whitelabelStore.config.network_mainnet_chain_id),
    name: whitelabelStore.config.network_mainnet_chain_name,
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },

    rpcUrls: {
      default: {
        http: [whitelabelStore.config.network_mainnet_rpc_url]
      }
    }
  })

  const whitelabel2 = defineChain({
    id: Number(whitelabelStore.config.network_testnet_chain_id),
    name: whitelabelStore.config.network_testnet_chain_name,
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },

    rpcUrls: {
      default: {
        http: [whitelabelStore.config.network_testnet_rpc_url]
      }
    }
  })

  const chains = [
    mainnet,
    polygon,
    polygonMumbai,
    sepolia,
    arbitrumSepolia,
    opCelestiaTestnet,
    polygonBlackberryTestnet,
    arbitrumBlueberryTestnet,
    whitelabel1,
    whitelabel2
  ]

  // Define chain from whitelabel

  const wagmiConfig = defaultWagmiConfig({
    chains,
    // transports: {
    //   [mainnet.id]: http(),
    //   [sepolia.id]: http(),
    //   [arbitrumSepolia.id]: http(),
    //   [opCelestiaTestnet.id]: http(),
    //   [polygonBlackberryTestnet.id]: http(),
    //   [arbitrumBlueberryTestnet.id]: http(),
    //   [polygon.id]: http(),
    //   [polygonMumbai.id]: http()
    // },
    auth: {
      email: false,
      socials: [],
      showWallets: true
      // walletFeatures?: boolean;
    },

    enableEmail: true, // Optional - false by default
    projectId: config.public.wcProjectID,
    metadata
  })

  const walletConnectModal = createWeb3Modal({
    wagmiConfig,
    projectId: config.public.wcProjectID,
    enableAnalytics: true // Optional - defaults to your Cloud configuration
  })

  // refs

  const account = ref(null)
  const balance = ref({ formatted: 0, value: 0 })

  // getters

  function getWalletAddress() {
    return account.value ? useAddressFormat(account.value.address) : null
  }

  // actions

  async function signData(chainId, contents, oracleaddress) {
    const signParams = {
      domain: {
        chainId,
        name: 'Oracle Builder',
        verifyingContract: '0xCcCCccccCCCCcCCCCCCcCcCccCcCCCcCcccccccC',
        version: '1'
      },
      types: {
        Oracle: [
          { name: 'contents', type: 'string' },
          { name: 'creator', type: 'address' },
          { name: 'oracleaddress', type: 'address' }
        ]
      },
      message: {
        contents,
        creator: account.value.address,
        oracleaddress
      },
      primaryType: 'Oracle'
    }

    try {
      return await signTypedData(wagmiConfig, { ...signParams })
    } catch (error) {
      console.error(error)

      return null
    }
  }

  async function getConnectedWalletBalance() {
    if (!account.value) return { formatted: 0, value: 0 }
    const balanceResponse = await getBalance(wagmiConfig, {
      address: account.value.address,
      chainId: account.value.chainId
    })

    balance.value = balanceResponse
    return balanceResponse
  }

  async function logout() {
    await disconnect(wagmiConfig)
    account.value = null
    balance.value = { formatted: 0, value: 0 }

    accountStore.team = []
    accountStore.account = null
    accountStore.viewDashboardSignedData = null
  }

  return {
    account,
    getWalletAddress,
    wagmiConfig,
    walletConnectModal,
    signData,
    getConnectedWalletBalance,
    balance,
    logout
  }
})

<template>
  <div class="top-bar" ref="refBar">
    <div class="info asset">
      <div class="label">{{ texts[0].label }}</div>
      <div class="value large">
        <img
          :src="getTokenIconUrl(newOraclesStore.selectedFeed.symbol)"
          width="30"
          height="30"
        />
        <div>
          {{ newOraclesStore.selectedFeed.symbol }} / USD
          <div class="mobile-price">
            $
            {{
              formatPrice(
                parseInt(newOraclesStore.selectedFeed.lastPrice) / 10 ** 8
              )
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="info mobile-hide">
      <div class="label">{{ texts[3].label }}</div>
      <div class="value">
        $
        {{
          formatPrice(
            parseInt(newOraclesStore.selectedFeed.lastPrice) / 10 ** 8
          )
        }}
      </div>
    </div>
    <div class="info last-update">
      <div class="label">{{ texts[4].label }}</div>
      <div class="value">
        <div class="">
          {{ getTimeAgo(newOraclesStore.selectedFeed.lastReportedTime, true) }}
        </div>
        <div class="time mobile-hide">
          {{ parseDate(newOraclesStore.selectedFeed.lastReportedTime).time }}
        </div>
      </div>
    </div>
    <div class="info mobile-hide">
      <div class="label">{{ texts[2].label }}</div>
      <div class="value">
        {{ countHeartbeat(newOraclesStore.selectedFeed.heartbeat, true) }}
        <span v-if="newOraclesStore.selectedFeed.deviation"
          >+ {{ newOraclesStore.selectedFeed.deviation }}% deviation</span
        >
      </div>
    </div>
    <div class="info mobile-hide">
      <div class="label">{{ texts[1].label }}</div>
      <div class="value">
        <!-- TODO: change to real value -->
        {{ shortenAddress(newOraclesStore.selectedFeed.address) }}
        <DeployerUiCopy
          :copy-value="newOraclesStore.selectedFeed.address"
          class="ml5"
        />
      </div>
    </div>
    <div class="spacer"></div>
    <button
      type="button"
      class="close mobile-hide"
      @click.prevent="newOraclesStore.showFeedDetails = false"
    ></button>
  </div>
</template>

<script setup>
const newOraclesStore = useNewOraclesStore()

const textsStore = useTextsStore()
const texts = computed(() => {
  return textsStore.list.price_feeds.table_labels
})

const refBar = ref(null)

const emits = defineEmits(['close-swiping'])

const { lengthY, direction } = useSwipe(refBar, {
  passive: false,
  threshold: 0,
  onSwipe: (e) => {
    if (lengthY.value < -60) {
      emits('close-swiping', 1)
      newOraclesStore.showFeedDetails = false
    } else {
      emits('close-swiping', lengthY.value)
    }
  },
  onSwipeEnd: (e) => {
    emits('close-swiping', 1)
    if (lengthY.value < -20) {
      newOraclesStore.showFeedDetails = false
    }
  }
})
</script>

<style lang="scss" scoped>
.top-bar {
  border-bottom: 1px solid var(--Block-stroke, rgba(0, 0, 0, 0.1));
  background: var(--Block-bg, #fff);
  padding: 20px 30px;
  display: flex;
  align-items: center;
  gap: 30px;

  @media (max-width: 1023px) {
    padding: 28px 15px 20px 15px;
    justify-content: space-between;
    gap: 10px;
    position: sticky;
    z-index: 9;
    top: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    &::after {
      content: '';
      width: 40px;
      height: 5px;
      border-radius: 30px;
      background: var(--bubble-10, rgba(0, 0, 0, 0.1));
      position: absolute;
      left: calc(50% - 20px);
      top: 10px;
    }
  }

  .label {
    margin-bottom: 6px;
  }

  @media (max-width: 1023px) {
    .last-update .label {
      margin-bottom: 0;
    }
    .info:not(.last-update) .label {
      display: none;
    }
  }

  .value {
    min-height: 30px;
    display: flex;
    align-items: center;
  }

  .mobile-hide {
    @media (max-width: 1023px) {
      display: none;
    }
  }
}

.spacer {
  flex: 1 1 0;
}

.close {
  border-radius: 6px;
  border: 1.5px solid var(--bubble-10, rgba(0, 0, 0, 0.1));
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 11px;
    height: 1.5px;
    background: var(--Text, #000);
    opacity: 0.5;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.asset {
  @media (max-width: 1023px) {
    width: calc(100% - 135px);
  }
}

.mobile-price {
  display: none;

  @media (max-width: 1023px) {
    display: block;
    opacity: 0.7;
    font-size: 1.5rem;
  }
}
</style>

<template>
  <div class="plans">
    <button
      v-for="(plan, index) in deployerStore.plans"
      :key="index"
      type="button"
      class="plan"
      :class="{ selected: plan.selected }"
      @click.prevent="selectPlan(plan.name)"
    >
      <div class="top">
        <div class="tag" v-html="plan.name" />
        <DeployerUiFormRadioButton :is-checked="plan.selected" />
      </div>
      <div class="price">
        $ {{ plan.price.toLocaleString('en-US') }}<span>/month</span>
      </div>
      <div class="extra">
        $ {{ plan.extra.toLocaleString('en-US')
        }}<span>/mo per extra feed</span>
      </div>
      <DeployerUiCheckedList :features="plan.features" />
    </button>
  </div>
</template>

<script setup>
const props = defineProps({
  whitelabelPlans: Object
})

const deployerStore = useDeployerStore()

const selectPlan = (planName) => {
  deployerStore.selectPlan(planName)
}
</script>

<style lang="scss" scoped>
.plans {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 25px;
}

.plan {
  border-radius: 10px;
  border: 1px solid var(--field-stroke, rgba(255, 255, 255, 0.2));
  background: var(--field-bg, rgba(255, 255, 255, 0.05));
  padding: 20px;
  flex: 1 1 0;
  min-width: 250px;
  color: var(--Text, #000);

  transition:
    border 0.2s,
    box-shadow 0.2s;

  &.selected {
    border: 1px solid var(--CTA-50, rgba(61, 110, 246, 0.5));
    box-shadow: 0px 0px 5px 0px rgba(80, 79, 228, 0.5);
  }

  &:hover {
    box-shadow: 0px 0px 5px 0px rgba(80, 79, 228, 0.5);
  }

  &:nth-child(1) {
    .tag {
      background: #ffeddc;
      color: #ff8c4c;
    }
  }
  &:nth-child(2) {
    .tag {
      background: #e1dcff;
      color: #654cff;
    }
  }
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.tag {
  font-weight: 500;
  line-height: 100%;
  padding: 5px;
  border-radius: 5px;
  display: inline-block;
}

.price,
.extra {
  span {
    opacity: 0.25;
  }
}

.price {
  font-size: 2.4rem;
  font-weight: 500;
  margin-bottom: 15px;
}

.extra {
  margin-bottom: 25px;
}
</style>

import { default as billingsYTvtwrJnrMeta } from "/workspace/pages/account/billing.vue?macro=true";
import { default as indexeEKJArLYGsMeta } from "/workspace/pages/account/index.vue?macro=true";
import { default as planFV3LCSjdPOMeta } from "/workspace/pages/account/plan.vue?macro=true";
import { default as support7AxOUAk3cFMeta } from "/workspace/pages/account/support.vue?macro=true";
import { default as team2yIEqthxwuMeta } from "/workspace/pages/account/team.vue?macro=true";
import { default as accountBoGQzqSbHbMeta } from "/workspace/pages/account.vue?macro=true";
import { default as deployLfgcn0gxxUMeta } from "/workspace/pages/deploy.vue?macro=true";
import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
import { default as listZvtqjxhaAZMeta } from "/workspace/pages/list.vue?macro=true";
export default [
  {
    name: accountBoGQzqSbHbMeta?.name,
    path: "/account",
    component: () => import("/workspace/pages/account.vue"),
    children: [
  {
    name: "account-billing",
    path: "billing",
    component: () => import("/workspace/pages/account/billing.vue")
  },
  {
    name: "account",
    path: "",
    component: () => import("/workspace/pages/account/index.vue")
  },
  {
    name: "account-plan",
    path: "plan",
    component: () => import("/workspace/pages/account/plan.vue")
  },
  {
    name: "account-support",
    path: "support",
    component: () => import("/workspace/pages/account/support.vue")
  },
  {
    name: "account-team",
    path: "team",
    component: () => import("/workspace/pages/account/team.vue")
  }
]
  },
  {
    name: "deploy",
    path: "/deploy",
    component: () => import("/workspace/pages/deploy.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/workspace/pages/index.vue")
  },
  {
    name: "list",
    path: "/list",
    component: () => import("/workspace/pages/list.vue")
  }
]
<template>
  <div class="inner-box mb60">
    <div class="inner-box__title">
      Top up <span class="inner-box__subtitle">via connected wallet</span>
    </div>
    <div v-if="!inProgress" class="form-wrapper">
      <div class="input-wrapper">
        <input
          v-model.number="topUpValue"
          type="number"
          min="0"
          placeholder="0"
          class="top-up-input"
          @change="deboucnedGetExpectedValues"
          @keyup="deboucnedGetExpectedValues"
        />
        <div class="wallet-info">
          <div class="icon-label right">
            <!-- TODO: Kamil change to real token -->
            <img
              alt="ETH icon"
              :src="getTokenIconUrl('ETH')"
              width="30"
              height="30"
            />
            ETH
            <!-- TODO: Kamil - change to real token -->
            <!-- {{ processStore.gasToken }} -->
          </div>
          <div class="balance">
            <span class="opacity70"
              >Balance:
              {{
                parseFloat(newWalletStore.balance.formatted).toFixed(2)
              }} </span
            >&nbsp;
            <button
              type="button"
              class="max-button"
              @click.prevent="topUpValue = newWalletStore.balance.formatted"
            >
              Max
            </button>
          </div>
        </div>
      </div>

      <div
        class="top-up-info"
        :class="{ show: topUpValue !== 0 && topUpValue !== '' }"
      >
        <div class="top-up-info__inner">
          <div class="info-row">
            <div class="info-row__label">Expected top up</div>
            <div class="info-row__value">{{ topUpValue }} ETH</div>
          </div>
          <div class="info-row">
            <div class="info-row__label">Expected oracle updates</div>
            <div class="info-row__value">{{ expectedUpdates }} updates</div>
          </div>

          <div class="info-row">
            <div class="info-row__label">Expected network fee</div>
            <div class="info-row__value">
              ~${{ parseFloat(networkFee).toFixed(6) }}
            </div>
          </div>
        </div>
      </div>
      <button
        type="button"
        class="btn cta w100 mt30"
        :disabled="!topUpValue"
        @click.prevent="doTopUp"
      >
        Confirm Top Up
      </button>

      <div v-if="error" class="error text-center mt15 fz14">
        {{ error }}
      </div>
    </div>
    <p v-else v-html="textsStore.list.top_up_progress.text" />
  </div>
</template>

<script setup>
import { formatEther, parseEther } from 'viem'
import { getGasPrice, sendTransaction } from '@wagmi/core'

const newWalletStore = useNewWalletStore()
const newOracleStore = useNewOraclesStore()
const textsStore = useTextsStore()

const expectedUpdates = ref(0)
const networkFee = ref(0)
const topUpValue = ref(null)
const intervalKeeper = ref(null)
const error = ref(null)
const inProgress = ref(false)

const emit = defineEmits(['transferred', 'top-up-progress'])

// This functions must be improved when the api service is ready
// const getTotalUpdateCount = async (asset, deviationPermille, frecuency) => {
//   const { Address, Blockchain } = asset
//   const res = await fetch(
//     `https://api.diadata.org/v1/assetUpdates/${Blockchain}/${Address}/${deviationPermille.length ? deviationPermille : '0'}/${frecuency}`
//   )
//   const { UpdateCount } = await res.json()
//
//   return UpdateCount
// }

const estimateOracleUpdates = async (amount) => {
  const gasPrice = await getGasPrice(newWalletStore.wagmiConfig, {
    chainId: newWalletStore.account.chainId
  })

  const estimatedGasPerUpdate = 60000n
  const updatesCount =
    parseEther(amount.toString()) / (gasPrice * estimatedGasPerUpdate)

  return Math.floor(Number(updatesCount))
}

const estimateNetworkFee = async (amount) => {
  const gasPrice = await getGasPrice(newWalletStore.wagmiConfig, {
    chainId: newWalletStore.account.chainId
  })

  const gasLimit = 21000n
  return gasPrice * gasLimit
}

const calculateCosts = async () => {
  expectedUpdates.value = await estimateOracleUpdates(topUpValue.value)
  const fee = await estimateNetworkFee(topUpValue.value)
  networkFee.value = formatEther(fee)
}

const deboucnedGetExpectedValues = useDebounceFn(() => {
  calculateCosts()
}, 300)

const doTopUp = async () => {
  const feederAddress = newOracleStore.editedOracle.feederAddress
  const amount =
    typeof topUpValue.value === 'string'
      ? topUpValue.value
      : topUpValue.value.toString()
  if (amount > newWalletStore.balance.formatted) {
    error.value = 'Insufficient funds'
  } else {
    emit('top-up-progress')

    newOracleStore.topUpTXHash = await sendTransaction(
      newWalletStore.wagmiConfig,
      {
        chainId: newWalletStore.account.chainId,
        to: feederAddress,
        value: parseEther(amount)
      }
    )
    emit('transferred')
  }
}

watch(
  () => topUpValue.value,
  (newVal) => {
    if (newVal < 0) {
      topUpValue.value = 0
    }

    error.value = null
  }
)

onBeforeUnmount(() => {
  if (intervalKeeper.value) {
    clearInterval(intervalKeeper.value)
  }
})
</script>

<style lang="scss" scoped>
.input-wrapper {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 0;
}
.top-up-input {
  background: transparent;
  border: 0;
  font-size: 5rem;
  color: var(--Text, #000);
  flex: 1 1 0;
  width: 60%;
  outline: none;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.max-button {
  color: var(--CTA, #3d6ef6);
  font-weight: 700;
}

.wallet-info {
  padding-top: 15px;
  font-size: 2.4rem;
  text-align: right;
}

.top-up-info {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.3s;

  &__inner {
    overflow: hidden;
    margin-top: 20px;
    border-top: 1px solid transparent;
    transition:
      padding-top 0.3s,
      margin-bottom 0.3s;

    .show & {
      border-top: 1px solid var(--bubble-10, rgba(0, 0, 0, 0.1));
      padding-top: 20px;
      margin-bottom: 30px;
    }
  }

  &.show {
    grid-template-rows: 1fr;
  }
}

.balance {
  margin-top: 15px;
  font-size: 1.7rem;
}
</style>

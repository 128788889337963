<template>
  <div>
    <p class="which" v-html="textsStore.list.top_up.which_wallet"></p>
    <div>
      <button
        type="button"
        class="mb10 row-btn"
        @click.prevent="selectWallet('connected')"
      >
        <div
          class="row-btn__title"
          v-html="textsStore.list.top_up.boxes[0].title"
        ></div>
        <p
          class="row-btn__text"
          v-html="textsStore.list.top_up.boxes[0].text"
        ></p>
      </button>
      <button
        type="button"
        class="row-btn"
        @click.prevent="selectWallet('different')"
      >
        <div
          class="row-btn__title"
          v-html="textsStore.list.top_up.boxes[1].title"
        ></div>
        <p
          class="row-btn__text"
          v-html="textsStore.list.top_up.boxes[1].text"
        ></p>
      </button>
    </div>
  </div>
</template>

<script setup>
const textsStore = useTextsStore()
const emit = defineEmits(['selectWallet'])

const selectWallet = (type) => {
  emit('selectWallet', type)
}
</script>

<style lang="scss" scoped>
.which {
  opacity: 0.9;
  font-size: 1.9rem;
}

.row-btn {
  border-radius: 20px;
  border: 1.5px solid var(--field-stroke, rgba(0, 0, 0, 0.2));
  background: var(--field-bg, rgba(0, 0, 0, 0.05));
  cursor: pointer;
  gap: 15px;
  padding: 25px 30px 25px 20px;
  transition: border-color 0.2s;

  &:first-child {
    margin-bottom: 10px;
  }

  &__title {
    letter-spacing: 0.01em;
    font-size: 2rem;
    font-weight: 500;
  }

  &__text {
    margin-bottom: 0;
    font-size: 1.6rem;
    margin-top: 6px;
    letter-spacing: 0.16px;
  }

  &:hover {
    border-color: var(--CTA, #3d6ef6);
  }
}
</style>

<template>
  <div v-if="modalStore.show" class="modal-overlay" :class="modalStore.type">
    <div
      ref="refModal"
      class="modal"
      :class="{ wide: isWide, medium: isMedium }"
    >
      <div
        v-if="modalStore.subtitle"
        class="subtitle"
        v-html="modalStore.subtitle"
      />
      <div class="title-row">
        <div class="title-icon">
          <div class="title" v-html="modalStore.title" />
          <img
            v-if="modalStore.isLoading"
            src="/images/loading.gif"
            width="22"
            height="22"
          />
          <div
            v-else-if="modalStore.type === 'top-up'"
            class="icon"
            v-html="TopUpSvg"
          />
        </div>
        <button
          v-if="modalStore.canClose"
          type="button"
          class="close"
          @click="modalStore.show = false"
        />
      </div>
      <DeployerUiTeamName v-if="showTeamName" />
      <p class="text opacity-80" v-html="modalStore.text" />
      <DeployerModalsUpdateHistory v-if="modalStore.type === 'updates'" />
      <DeployerModalsOracleTopUp v-else-if="modalStore.type === 'top-up'" />
      <DeployerModalsCancelPlan v-else-if="modalStore.type === 'cancel-plan'" />
      <DeployerModalsJoinTeam v-else-if="modalStore.type === 'join-team'" />
      <DeployerModalsCreateTeam v-else-if="modalStore.type === 'create-team'" />
      <DeployerModalsSelectPlan v-else-if="modalStore.type === 'select-plan'" />
    </div>
  </div>
</template>

<script setup>
import TopUpSvg from '~/layers/base/assets/icons/top-up.svg?raw'

const modalStore = useModalStore()
const textsStore = useTextsStore()
const refModal = ref(null)

const isWide = computed(() => {
  if (modalStore.type === 'updates' || modalStore.type === 'select-plan') {
    return true
  }

  return false
})

const showTeamName = computed(() => {
  if (modalStore.type === 'select-plan') {
    return true
  }

  return false
})

const isMedium = computed(() => {
  if (modalStore.type === 'join-team' || modalStore.type === 'create-team') {
    return true
  }

  return false
})

watch(
  () => modalStore.type,
  (newVal) => {
    if (newVal === 'join-team') {
      modalStore.subtitle = textsStore.account.join_team_modal.subtitle
      modalStore.title = textsStore.account.join_team_modal.title
      modalStore.text = textsStore.account.join_team_modal.text
      modalStore.canClose = false
    } else if (newVal === 'create-team') {
      modalStore.subtitle = textsStore.account.create_team_modal.subtitle
      modalStore.title = textsStore.account.create_team_modal.title
      modalStore.text = textsStore.account.create_team_modal.text
      modalStore.canClose = false
    } else if (newVal === 'select-plan') {
      modalStore.subtitle = textsStore.account.select_plan_modal.subtitle
      modalStore.title = textsStore.account.select_plan_modal.title
      modalStore.text = textsStore.account.select_plan_modal.text
      modalStore.canClose = false
    }
  }
)

onClickOutside(refModal, () => {
  if (modalStore.canClose) {
    modalStore.show = false
    modalStore.oracle = {}
  }
})
</script>

<style lang="scss" scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 10vh;
  overflow-y: auto;
}

.modal {
  padding: 40px;
  background: var(--Page-bg, #fafafa);
  max-width: 550px;
  width: 100%;
  border-radius: 20px;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.5);
  color: var(--Text, #000);
  position: relative;
  min-height: 400px;

  @media (max-width: 750px) {
    padding: 20px;
  }

  .cancel-plan & {
    padding: 80px;
  }

  :deep(.info-row) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .info-row__label {
      font-weight: 400;
    }

    .info-row__value {
      font-weight: 500;
    }
  }

  :deep(.team-name) {
    position: absolute;
    right: 40px;
    top: 35px;
  }

  :deep(.line) {
    border: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    margin-block: 30px;
  }

  &.wide {
    max-width: 800px;
  }
  &.medium {
    max-width: 650px;
  }
}

.title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.subtitle {
  font-size: 1.3rem;
  font-weight: 500;
  opacity: 0.4;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.title {
  font-size: 3rem;
  font-weight: 500;
  line-height: 120%;

  @media (max-width: 750px) {
    font-size: 2rem;
  }
}

.text {
  font-size: 1.7rem;
  margin-bottom: 40px;

  :deep(a) {
    text-decoration: underline;
    color: var(--CTA, #3d6ef6);
  }
}

:deep(.inner-box) {
  border-radius: 20px;
  background: var(--bubble-10, rgba(0, 0, 0, 0.1));
  padding: 20px;

  .inner-box__title {
    font-size: 2rem;
  }

  .inner-box__subtitle {
    opacity: 0.5;
  }

  .inner-box__text {
    margin-block: 20px;
  }
}

:deep(.info-label) {
  opacity: 0.8;
  margin-bottom: 5px;
}

.close {
  width: 20px;
  height: 20px;
  border: none;
  cursor: pointer;
  position: absolute;

  right: 40px;
  top: 40px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0px;
    width: 20px;
    height: 2px;
    background: var(--Text, #000);
    transition: transform 0.3s;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &:hover {
    &::before,
    &::after {
      transform: rotate(0deg);
    }
  }
}

.title-icon {
  display: flex;
  align-items: center;
  gap: 10px;
  line-height: 100%;

  .icon {
    height: 22px;
  }
}
</style>
